import React from "react";

const Overview = () => {
  return (
    <div>
      {/* <div
        class="tab-pane fade show active"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="single-course-details ">
          <h4 class="course-title">Description</h4>
          <p>
            Knowing PHP has allowed me to make enough money to stay home and
            make courses like this one for students all over the world. Being a
            PHP developer can allow anyone to make really good money online and
            offline, developing dynamic applications. Knowing PHP will allow you
            to build web applications, websites or Content Management systems,
            like WordPress, Facebook, Twitter or even Google. There is no limit
            to what you can do with this knowledge. PHP is one of the most
            important web programming languages to learn, and knowing it, will
            give you SUPER POWERS in the web
          </p>

          <div class="course-widget course-info">
            <h4 class="course-title">What You will Learn?</h4>
            <ul>
              <li>
                <i class="fa fa-check"></i>
                Clean up face imperfections, improve and repair photos
              </li>
              <li>
                <i class="fa fa-check"></i>
                Remove people or objects from photos
              </li>
              <li>
                <i class="fa fa-check"></i>
                Master selections, layers, and working with the layers panel
              </li>
              <li>
                <i class="fa fa-check"></i>
                Use creative effects to design stunning text styles
              </li>
              <li>
                <i class="fa fa-check"></i>
                working with the layers panel
              </li>
              <li>
                <i class="fa fa-check"></i>
                Cut away a person from their background
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <h1>Hello</h1>
    </div>
  );
};

export default Overview;
