import React from "react";

const Courseheader = ({
  courseTitle,
  courseDescription,
  author,
  authorImage,
  totalStudents,
  rating,
}) => {
  return (
    <div class="col-lg-7 col-xl-7">
      <div class="course-header-wrapper mb-0 bg-transparent">
        <h1 class="mb-3">{courseTitle}</h1>
        <p>
          Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion
          consectetur elit. Vesti at bulum nec odio. Dacere agemusque
          constantius concessis elit videmusne quia stoici constructio
          dissimillimas audiunt homerus commendationes
        </p>

        <div class="course-header-meta">
          <ul class="inline-list list-info">
            <li>
              <div class="course-author">
                <img src={authorImage} alt="#" />
                {author}
              </div>
            </li>

            <li>
              <div class="list-rating">
                <span>
                  <i class="fas fa-star"></i>
                </span>
                <span>
                  <i class="fas fa-star"></i>
                </span>
                <span>
                  <i class="fas fa-star"></i>
                </span>
                <span>
                  <i class="fas fa-star"></i>
                </span>
                <span>
                  <i class="fas fa-star"></i>
                </span>
                <span class="rating-count">({rating})</span>
              </div>
            </li>
            <li>
              <i class="fa fa-user me-2"></i>
              {totalStudents} enrolled students
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Courseheader;
