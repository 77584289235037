import React from "react";

const Courses = ({
  img1,
  img2,
  price,
  instructor,
  title,
  duration,
  lectureNumber,
}) => {
  return (
    <div class="col-xl-4 col-lg-6 col-md-6">
      <div class="course-grid course-style-4 bg-white mb-80">
        <div class="course-header">
          <div class="course-thumb">
            <img src={img2} alt="" class="img-fluid" />
          </div>
        </div>

        <div class="course-content">
          <span class="course-price">{price}</span>
          <div class="d-flex align-items-center">
            <div class="author me-3">
              <img src={img1} alt="" class="img-fluid" />
              <a href="#">{instructor}</a>
            </div>
          </div>

          <h3 class="course-title">
            {" "}
            <a href="#">{title}</a>{" "}
          </h3>
          <div class="course-meta">
            <span class="duration">
              <i class="far fa-user-alt"></i>
              {duration}
            </span>
            <span class="lessons">
              <i class="far fa-play-circle me-2"></i>
              {lectureNumber}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
