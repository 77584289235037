import React, { useEffect, useState } from "react";
//CSS file
import "./../../../Vendors/bootstrap/bootstrap.css";
import "./../../../Vendors/awesome/css/fontawesome-all.min.css";
import "./../../../Vendors/flaticon/flaticon.css";
import "./../../../Vendors/magnific-popup/magnific-popup.css";
import "./../../../Vendors/animate-css/animate.css";
import "./../../../Vendors//owl/assets/owl.theme.default.min.css";
// import "./../../../Vendors/owl/assets/owl.carousel.min.css";
// import "./../../../Vendors/animated-headline/animated-headline.css";

import "./../../../Css/woocomerce.css";
import "./../../../Css/responsive.css";
import "./../../../Css/style.css";

// //Javascript File
// import "./../../../Vendors/jquery/jquery";
// import "./../../../Vendors/counterup/waypoint";
// import "./../../../Vendors/counterup/jquery.counterup.min.js";
// import "./../../../Vendors/bootstrap/bootstrap";
// import "./../../../Vendors/bootstrap/popper.min.js";
//import "./../../../Vendors/owl/owl.carousel.min.js";
// import "../../../Vendors/isotope/isotope1.js";
// import "./../../../Vendors/isotope/imagelaoded.min.js";
// import "./../../../Vendors/animated-headline/animated-headline.js";
// import "./../../../Vendors/magnific-popup/jquery.magnific-popup.min.js";

//Image
import logopik from "./../../../Images/logo.png";
import crspik06 from "./../../../Images/banner/4102879_971_prev_ui.png";
import courseImage06 from "./../../../Images/course/img_06.jpg";
import courseImg02 from "./../../../Images/course/course-2.jpg";
import courseImg03 from "./../../../Images/course/img_03.jpg";
import courseImg01 from "./../../../Images/course/img_01.jpg";
import courseImg04 from "./../../../Images/course/img_04.jpg";
import courseImg05 from "./../../../Images/course/img_05.jpg";
import iconProgramming from "./../../../Images/icon/icons8-c-programming-144.png";
import icon2 from "./../../../Images/icon/icon2.png";
import icon3 from "./../../../Images/icon/icon3.png";
import icon4 from "./../../../Images/icon/icon4.png";
import icon6 from "./../../../Images/icon/icon6.png";
import bgoffice01 from "./../../../Images/bg/about-dots.png";
import team1 from "./../../../Images/team/team-1.jpg";
import team2 from "./../../../Images/team/team-2.jpg";
import team3 from "./../../../Images/team/team-3.jpg";
import team4 from "./../../../Images/team/team-4.jpg";
import banner11 from "./../../../Images/banner/img_11.png";
import avata01 from "./../../../Images/clients/testimonial-avata-01.jpg";
import avata02 from "./../../../Images/clients/testimonial-avata-02.jpg";
import avata03 from "./../../../Images/clients/testimonial-avata-03.jpg";
import avata04 from "./../../../Images/clients/testimonial-avata-04.jpg";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import AllReviews from "../Reviews/AllReviews";
import Loader from "../Shared/Loader/Loader";
import CycleLoader from "../Shared/Loader/CycleLoader";
const Home = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <CycleLoader></CycleLoader>
      ) : (
        <div>
          <Header></Header>
          {/* <!--====== Header End ======-->
  <!-- Banner Section Start --> */}
          <section className="banner-style-4 banner-padding">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-12 col-xl-6 col-lg-6">
                  <div className="banner-content ">
                    <span className="subheading">
                      Over 3000 Course Available
                    </span>
                    <h1>New Online Learning Platform</h1>
                    <p className="mb-40"> Learn with us!!</p>

                    <div className="btn-container">
                      <Link to="/blog" className="btn btn-main rounded">
                        {" "}
                        Find Courses
                      </Link>

                      <a href="#" className="btn btn-white rounded ms-2">
                        Get started{" "}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-xl-6 col-lg-6">
                  <div className="banner-img-round mt-5 mt-lg-0 ps-5">
                    <img src={crspik06} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              {/* <!-- / .row --> */}
            </div>
            {/* <!-- / .container --> */}
          </section>
          {/* <!-- Banner Section End -->
  
  <!-- Counter Section start --> */}
          <section className="counter-section4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-12 counter-inner">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="counter-item mb-5 mb-lg-0">
                        <div className="count">
                          <span className="counter h2">2000</span>
                          <span>+</span>
                        </div>
                        <p>Students</p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="counter-item mb-5 mb-lg-0">
                        <div className="count">
                          <span className="counter h2">1200</span>
                        </div>
                        <p>Online Courses</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="counter-item mb-5 mb-lg-0">
                        <div className="count">
                          <span className="counter h2">2256</span>
                        </div>
                        <p>Finished Seasons</p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="counter-item">
                        <div className="count">
                          <span className="counter h2">100</span>
                          <span>%</span>
                        </div>
                        <p>Satisfaction</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- COunter Section End -->
  
  
  
  
  <!--  Course style 1 --> */}
          <section className="course-wrapper section-padding ">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <div className="section-heading mb-70 text-center">
                    <h2 className="font-lg">Popular Courses</h2>
                    <p>Discover Your Perfect Program In Our Courses.</p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="course-grid bg-shadow tooltip-style">
                    <div className="course-header">
                      <div className="course-thumb">
                        <img src={courseImage06} alt="" className="img-fluid" />
                        <div className="course-price">$300</div>
                      </div>
                    </div>

                    <div className="course-content">
                      <div className="rating mb-10">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>

                        <span>3.9 (30 reviews)</span>
                      </div>

                      <h3 className="course-title mb-20">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>

                      <div className="course-footer mt-20 d-flex align-items-center justify-content-between ">
                        <span className="duration">
                          <i className="far fa-clock me-2"></i>6.5 hr
                        </span>
                        <span className="students">
                          <i className="far fa-user-alt me-2"></i>51 Students
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>26 Lessons
                        </span>
                      </div>
                    </div>

                    <div className="course-hover-content">
                      <div className="price">$300</div>
                      <h3 className="course-title mb-20 mt-30">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>
                      <div className="course-meta d-flex align-items-center mb-20">
                        <div className="author me-4">
                          <img src={courseImg02} alt="" className="img-fluid" />
                          <a href="#">Josephin</a>
                        </div>
                        <span className="lesson">
                          {" "}
                          <i className="far fa-file-alt"></i> 20 lessons
                        </span>
                      </div>
                      <p className="mb-20">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Libero, culpa. At voluptatem autem ipsam deleniti
                      </p>
                      <a href="#" className="btn btn-grey btn-sm rounded">
                        Join Now <i className="fal fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <!-- COURSE END --> */}

                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="course-grid bg-shadow tooltip-style">
                    <div className="course-header">
                      <div className="course-thumb">
                        <img src={courseImage06} alt="" className="img-fluid" />
                        <div className="course-price">$300</div>
                      </div>
                    </div>

                    <div className="course-content">
                      <div className="rating mb-10">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>

                        <span>3.9 (30 reviews)</span>
                      </div>

                      <h3 className="course-title mb-20">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>
                      <div className="course-footer mt-20 d-flex align-items-center justify-content-between ">
                        <span className="duration">
                          <i className="far fa-clock me-2"></i>6.5 hr
                        </span>
                        <span className="students">
                          <i className="far fa-user-alt me-2"></i>51 Students
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>26 Lessons
                        </span>
                      </div>
                    </div>

                    <div className="course-hover-content">
                      <div className="price">$300</div>
                      <h3 className="course-title mb-20 mt-30">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>
                      <div className="course-meta d-flex align-items-center mb-20">
                        <div className="author me-4">
                          <img src={courseImg02} alt="" className="img-fluid" />
                          <a href="#">Josephin</a>
                        </div>
                        <span className="lesson">
                          {" "}
                          <i className="far fa-file-alt"></i> 20 lessons
                        </span>
                      </div>
                      <p className="mb-20">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Libero, culpa. At voluptatem autem ipsam deleniti
                      </p>
                      <a href="#" className="btn btn-grey btn-sm rounded">
                        Join Now <i className="fal fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <!-- COURSE END -->
                 */}
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="course-grid bg-shadow tooltip-style">
                    <div className="course-header">
                      <div className="course-thumb">
                        <img src={courseImg03} alt="" className="img-fluid" />
                        <div className="course-price">$300</div>
                      </div>
                    </div>

                    <div className="course-content">
                      <div className="rating mb-10">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>

                        <span>3.9 (30 reviews)</span>
                      </div>

                      <h3 className="course-title mb-20">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>

                      <div className="course-footer mt-20 d-flex align-items-center justify-content-between ">
                        <span className="duration">
                          <i className="far fa-clock me-2"></i>6.5 hr
                        </span>
                        <span className="students">
                          <i className="far fa-user-alt me-2"></i>51 Students
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>26 Lessons
                        </span>
                      </div>
                    </div>

                    <div className="course-hover-content">
                      <div className="price">$300</div>
                      <h3 className="course-title mb-20 mt-30">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>
                      <div className="course-meta d-flex align-items-center mb-20">
                        <div className="author me-4">
                          <img src={courseImg02} alt="" className="img-fluid" />
                          <a href="#">Josephin</a>
                        </div>
                        <span className="lesson">
                          {" "}
                          <i className="far fa-file-alt"></i> 20 lessons
                        </span>
                      </div>
                      <p className="mb-20">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Libero, culpa. At voluptatem autem ipsam deleniti
                      </p>
                      <a href="#" className="btn btn-grey btn-sm rounded">
                        Join Now <i className="fal fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <!-- COURSE END --> */}
              </div>
            </div>
          </section>
          {/* <!--  Course style 1 End -->
  
  
  
  
  <!--  Course category --> */}
          <section className="course-category-3 section-padding">
            <div className="container">
              <div className="row mb-70 justify-content-center">
                <div className="col-xl-8">
                  <div className="section-heading text-center">
                    <h2 className="font-lg">Categories you want to learn</h2>
                    <p>
                      Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl col-lg-4 col-sm-6">
                  <div className="single-course-category style-3 bg-1">
                    <div className="course-cat-icon">
                      <img src={iconProgramming} alt="" className="img-fluid" />
                    </div>
                    <div className="course-cat-content">
                      <h4 className="course-cat-title">
                        <a href="#">
                          C <br />
                          Programming
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-xl col-lg-4 col-sm-6">
                  <div className="single-course-category style-3 bg-2">
                    <div className="course-cat-icon">
                      <img src={icon2} alt="" className="img-fluid" />
                    </div>
                    <div className="course-cat-content">
                      <h4 className="course-cat-title">
                        <a href="#">Artificial Intellegence</a>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-xl col-lg-4 col-sm-6">
                  <div className="single-course-category style-3 bg-3">
                    <div className="course-cat-icon">
                      <img src={icon3} alt="" className="img-fluid" />
                    </div>
                    <div className="course-cat-content">
                      <h4 className="course-cat-title">
                        <a href="#">Algebra Math calculation</a>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-xl col-lg-4 col-sm-6">
                  <div className="single-course-category style-3 bg-4">
                    <div className="course-cat-icon">
                      <img src={icon4} alt="" className="img-fluid" />
                    </div>
                    <div className="course-cat-content">
                      <h4 className="course-cat-title">
                        <a href="#">Web Development</a>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-xl col-lg-4 col-sm-6">
                  <div className="single-course-category style-3 bg-5">
                    <div className="course-cat-icon">
                      <img src={icon6} alt="" className="img-fluid" />
                    </div>
                    <div className="course-cat-content">
                      <h4 className="course-cat-title">
                        <a href="#">Digital Marketing & SEO</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--  Course category End --> */}
          {/* <!-- Work Process Section Start --> */}
          <section className="work-process section-padding">
            <div className="container">
              <div className="row mb-40">
                <div className="col-xl-8">
                  <div className="section-heading ">
                    <h2 className="font-lg">Start your journey With us</h2>
                    <p>
                      Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                    </p>
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-xl-7 pe-xl-5 col-lg-12">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="step-item ">
                        <div className="step-number bg-1">01</div>
                        <div className="step-text">
                          <h5>Signup with all info</h5>
                          <p>
                            Lorem ipsum dolor seat ameat dui too consecteture
                            elite adipaising.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="step-item">
                        <div className="step-number bg-2">02</div>
                        <div className="step-text">
                          <h5>Take your Admission</h5>
                          <p>
                            Lorem ipsum dolor seat ameat dui too consecteture
                            elite adipaising.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 ">
                      <div className="step-item">
                        <div className="step-number bg-3">03</div>
                        <div className="step-text">
                          <h5>Learn from online </h5>
                          <p>
                            Lorem ipsum dolor seat ameat dui too consecteture
                            elite adipaising.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="step-item">
                        <div className="step-number bg-1">04</div>
                        <div className="step-text">
                          <h5>Get certificate</h5>
                          <p>
                            Lorem ipsum dolor seat ameat dui too consecteture
                            elite adipaising.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-5">
                  <div className="video-section mt-3 mt-xl-0">
                    <div className="video-content">
                      <img src={bgoffice01} alt="" className="img-fluid" />
                      <a href="#" className="video-icon video-popup">
                        <i className="fa fa-play"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Work Process Section End -->
  <!--  Course style 1 --> */}
          <section className="course-wrapper section-padding  bg-gray">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <div className="section-heading mb-70 text-center">
                    <h2 className="font-lg">Popular Courses</h2>
                    <p>Discover Your Perfect Program In Our Courses.</p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="course-grid tooltip-style bg-white hover-shadow">
                    <div className="course-header">
                      <div className="course-thumb">
                        <img src={courseImg01} alt="" className="img-fluid" />
                        <div className="course-price">$300</div>
                      </div>
                    </div>

                    <div className="course-content">
                      <div className="rating mb-10">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>

                        <span>3.9 (30 reviews)</span>
                      </div>
                      <h3 className="course-title mb-20">
                        {" "}
                        <a href="#">SQL-Data Analysis: Crash Course</a>{" "}
                      </h3>
                      <div className="course-footer mt-20 d-flex align-items-center justify-content-between ">
                        <span className="duration">
                          <i className="far fa-clock me-2"></i>6.5 hr
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>26 Lessons
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- COURSE END --> */}

                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="course-grid tooltip-style bg-white hover-shadow">
                    <div className="course-header">
                      <div className="course-thumb">
                        <img src={courseImg05} alt="" className="img-fluid" />
                        <div className="course-price">$300</div>
                      </div>
                    </div>

                    <div className="course-content">
                      <div className="rating mb-10">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>

                        <span>3.9 (30 reviews)</span>
                      </div>
                      <h3 className="course-title mb-20">
                        {" "}
                        <a href="#">
                          Learn How to Start an Amazon FBA Store
                        </a>{" "}
                      </h3>
                      <div className="course-footer mt-20 d-flex align-items-center justify-content-between ">
                        <span className="duration">
                          <i className="far fa-clock me-2"></i>6.5 hr
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>26 Lessons
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- COURSE END --> */}

                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="course-grid tooltip-style bg-white hover-shadow">
                    <div className="course-header">
                      <div className="course-thumb">
                        <img src={courseImg03} alt="" className="img-fluid" />
                        <div className="course-price">$300</div>
                      </div>
                    </div>

                    <div className="course-content">
                      <div className="rating mb-10">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>

                        <span>3.9 (30 reviews)</span>
                      </div>

                      <h3 className="course-title mb-20">
                        {" "}
                        <a href="#">
                          Emotional Intelligence at Work: Learn Emotions
                        </a>{" "}
                      </h3>

                      <div className="course-footer mt-20 d-flex align-items-center justify-content-between ">
                        <span className="duration">
                          <i className="far fa-clock me-2"></i>6.5 hr
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>26 Lessons
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- COURSE END --> */}

                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="course-grid tooltip-style bg-white hover-shadow">
                    <div className="course-header">
                      <div className="course-thumb">
                        <img src={courseImg03} alt="" className="img-fluid" />
                        <div className="course-price">$300</div>
                      </div>
                    </div>

                    <div className="course-content">
                      <div className="rating mb-10">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>

                        <span>3.9 (30 reviews)</span>
                      </div>

                      <h3 className="course-title mb-20">
                        {" "}
                        <a href="#">
                          Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>

                      <div className="course-footer mt-20 d-flex align-items-center justify-content-between ">
                        <span className="duration">
                          <i className="far fa-clock me-2"></i>6.5 hr
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>26 Lessons
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- COURSE END --> */}
              </div>
            </div>
          </section>
          {/* <!--  Course style 1 End -->
  
  
  
  <!-- Feature section start --> */}
          <section className="features-3 section-padding-top ">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <div className="section-heading mb-50 text-center">
                    <h2 className="font-lg">Transform Your Life </h2>
                    <p>Discover Your Perfect Program In Our Courses.</p>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                  <div className="feature-item feature-style-top hover-shadow rounded border-0">
                    <div className="feature-icon">
                      <i className="flaticon-teacher"></i>
                    </div>
                    <div className="feature-text">
                      <h4>Expert Teacher</h4>
                      <p>
                        Develop skills for career of various majors including
                        computer
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                  <div className="feature-item feature-style-top hover-shadow rounded border-0">
                    <div className="feature-icon">
                      <i className="flaticon-layer"></i>
                    </div>
                    <div className="feature-text">
                      <h4>Self Development</h4>
                      <p>
                        Develop skills for career of various majors including
                        computer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                  <div className="feature-item feature-style-top hover-shadow rounded border-0">
                    <div className="feature-icon">
                      <i className="flaticon-video-camera"></i>
                    </div>
                    <div className="feature-text">
                      <h4>Remote Learning</h4>
                      <p>
                        Develop skills for career of various majors including
                        language
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                  <div className="feature-item feature-style-top hover-shadow rounded border-0">
                    <div className="feature-icon">
                      <i className="flaticon-lifesaver"></i>
                    </div>
                    <div className="feature-text">
                      <h4>Life Time Support</h4>
                      <p>
                        Develop skills for career of various majors including
                        language{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Feature section End -->
  <!-- Team section start --> */}
          <section className="team section-padding">
            <div className="container">
              <div className="row  mb-100">
                <div className="col-lg-8 col-xl-8">
                  <div className="section-heading text-center text-lg-start">
                    <h2 className="font-lg">Top Rated Instructors</h2>
                    <p>Discover Your Perfect Program In Our Courses.</p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4">
                  <div className="text-center text-lg-end">
                    <a href="#" className="btn btn-main-outline rounded">
                      All Instructors <i className="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="team-item team-item-4 mb-70 mb-xl-0">
                    <div className="team-img">
                      <img src={team4} alt="" className="img-fluid" />

                      <ul className="team-socials list-inline">
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-content">
                      <div className="team-info">
                        <h4>Harish Ham</h4>
                        <p>SEO Expert</p>
                      </div>

                      <div className="course-meta">
                        <span className="duration">
                          <i className="far fa-user-alt"></i>20 Students
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>2 Course
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="team-item team-item-4 mb-70 mb-xl-0">
                    <div className="team-img">
                      <img src={team1} alt="" className="img-fluid" />
                      <ul className="team-socials list-inline">
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-content">
                      <div className="team-info">
                        <h4>Harish Ham</h4>
                        <p>CEO, Developer</p>
                      </div>
                      <div className="course-meta">
                        <span className="duration">
                          <i className="far fa-user-alt"></i>20 Students
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>2 Course
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="team-item team-item-4 mb-70 mb-xl-0">
                    <div className="team-img">
                      <img src={team2} alt="" className="img-fluid" />

                      <ul className="team-socials list-inline">
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-content">
                      <div className="team-info">
                        <h4>Harish Ham</h4>
                        <p>Web eveloper</p>
                      </div>
                      <div className="course-meta">
                        <span className="duration">
                          <i className="far fa-user-alt"></i>20 Students
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>2 Course
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="team-item team-item-4">
                    <div className="team-img">
                      <img src={team3} alt="" className="img-fluid" />

                      <ul className="team-socials list-inline">
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-content">
                      <div className="team-info">
                        <h4>Harish Ham</h4>
                        <p>Marketer</p>
                      </div>

                      <div className="course-meta">
                        <span className="duration">
                          <i className="far fa-user-alt"></i>20 Students
                        </span>
                        <span className="lessons">
                          <i className="far fa-play-circle me-2"></i>2 Course
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Team section End -->
  
  <!-- Testimonial section start --> */}
          <AllReviews></AllReviews>
          {/* <!-- Testimonial section End -->
  <!-- CTA Sidebar start --> */}
          <section className="cta-5 mb--120 bg-gray">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="cta-inner4">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-xl-4 col-lg-5">
                        <div className="cta-img mb-4 mb-lg-0">
                          <img src={banner11} alt="" className="img-fluid" />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="cta-content ps-lg-4">
                          <span className="subheading mb-10">
                            Not sure where to start?
                          </span>
                          <h2 className="mb-20">
                            {" "}
                            Want to know Special Offers & Updates of new
                            courses?
                          </h2>
                          <a href="#" className="btn btn-main rounded">
                            {" "}
                            Join Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- CTA Sidebar end -->
  <!-- Footer section start --> */}
          {/* <section className="footer footer-3 pt-250">
          <div className="footer-mid">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-sm-8 me-auto">
                  <div className="widget footer-widget mb-5 mb-lg-0">
                    <div className="footer-logo">
                      <img src={logopik} alt="" className="img-fluid" />
                    </div>
  
                    <p className="mt-4">
                      Binary classNameroom is a blog site for computer science and
                      technology
                    </p>
  
                    <div className="footer-socials mt-5">
                      <span className="me-2">Connect :</span>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
  
                <div className="col-xl-2 col-sm-4">
                  <div className="footer-widget mb-5 mb-lg-0">
                    <h5 className="widget-title">Explore</h5>
                    <ul className="list-unstyled footer-links">
                      <li>
                        <a href="#">About us</a>
                      </li>
                      <li>
                        <a href="#">Contact us</a>
                      </li>
                      <li>
                        <a href="#">Services</a>
                      </li>
                      <li>
                        <a href="#">Support</a>
                      </li>
                    </ul>
                  </div>
                </div>
  
                <div className="col-xl-2 col-sm-4">
                  <div className="footer-widget mb-5 mb-lg-0">
                    <h5 className="widget-title ">Programs</h5>
                    <ul className="list-unstyled footer-links">
                      <li>
                        <a href="#">SEO Business</a>
                      </li>
                      <li>
                        <a href="#">Digital Marketing</a>
                      </li>
                      <li>
                        <a href="#">Graphic Design</a>
                      </li>
                      <li>
                        <a href="#">Social Marketing</a>
                      </li>
                    </ul>
                  </div>
                </div>
  
                <div className="col-xl-2 col-sm-4">
                  <div className="footer-widget mb-5 mb-lg-0">
                    <h5 className="widget-title">Links</h5>
                    <ul className="list-unstyled footer-links">
                      <li>
                        <a href="#">News & Blogs</a>
                      </li>
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="#">Support</a>
                      </li>
                      <li>
                        <a href="#">Return Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
  
                <div className="col-xl-2 col-sm-4">
                  <div className="footer-widget mb-5 mb-lg-0">
                    <h5 className="widget-title">Address</h5>
                    <ul className="list-unstyled footer-links">
                      <li>
                        <a href="#">+(880) 1571460272</a>
                      </li>
                      <li>
                        <a href="#">binaryclassroom@gmail.com</a>
                      </li>
                      <li>
                        <a href="#">
                          123 Fifth Floor East 26th Street,New York, NY 10011
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div className="footer-btm">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-sm-12 col-lg-6">
                  <p className="mb-0 copyright text-sm-center text-lg-start">
                    © 2023 All rights reserved by{" "}
                    <a href="#" rel="nofollow">
                      Binary It Limited
                    </a>{" "}
                  </p>
                </div>
  
                <div className="col-xl-6 col-sm-12 col-lg-6">
                  <div className="footer-btm-links text-start text-sm-center text-lg-end">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#"> Terms of Service</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">Join Us</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div className="fixed-btm-top">
            <a href="#top-header" className="js-scroll-trigger scroll-to-top">
              <i className="fa fa-angle-up"></i>
            </a>
          </div>
        </section> */}
          <Footer padding={true}></Footer>
        </div>
      )}
    </>
  );
};

export default Home;
