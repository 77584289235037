import React from "react";
import loader from "./../../../../Images/loader.gif";
const Loader = () => {
  return (
    <div className="min-w-[100vw] flex justify-center items-center bg-[black] min-h-[100vh]">
      <img src={loader} alt="" />
    </div>
  );
};

export default Loader;
