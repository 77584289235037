import React, { useContext } from "react";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
//Images
import { TfiWrite } from "react-icons/tfi";
import programmingicon from "./../../../Images/icon/icons8-c-programming-144.png";
import icon2 from "./../../../Images/icon/icon2.png";
import icon3 from "./../../../Images/icon/icon3.png";
import icon4 from "./../../../Images/icon/icon4.png";
import icon6 from "./../../../Images/icon/icon6.png";
import blogsm3 from "./../../../Images/blog//sm3.png";
import blogsm2 from "./../../../Images/blog//sm2.png";
import { blogContext } from "../../Context/blogContext";
import { useEffect } from "react";
import BlogCart from "./BlogCart";
import { Link } from "react-router-dom";
const Blog = () => {
  const { blogs } = useContext(blogContext);
  useEffect(() => {
    console.log(blogs);
    console.log(blogs.length);
  });
  return (
    <div>
      <Header></Header>
      <section class="page-header">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-8">
              <div class="title-block py-6">
                <h1>Blog Grid</h1>
                <ul class="header-bradcrumb justify-content-center">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li class="active" aria-current="page">
                    blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <<!--  Course category -->> */}
      <section class="course-category-3 section-padding">
        <div class="container">
          <div class="row mb-70 justify-content-center">
            <div class="col-xl-8">
              <div class="section-heading text-center">
                <h2 class="font-lg">Categories you want to learn</h2>
                <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl col-lg-4 col-sm-6">
              <div class="single-course-category style-3 bg-1">
                <div class="course-cat-icon">
                  <img src={programmingicon} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">
                      C <br />
                      Programming
                    </a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl col-lg-4 col-sm-6">
              <div class="single-course-category style-3 bg-2">
                <div class="course-cat-icon">
                  <img src={icon2} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Artificial Intellegence</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl col-lg-4 col-sm-6">
              <div class="single-course-category style-3 bg-3">
                <div class="course-cat-icon">
                  <img src={icon3} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Algebra Math calculation</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl col-lg-4 col-sm-6">
              <div class="single-course-category style-3 bg-4">
                <div class="course-cat-icon">
                  <img src={icon4} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Web Development</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl col-lg-4 col-sm-6">
              <div class="single-course-category style-3 bg-5">
                <div class="course-cat-icon">
                  <img src={icon6} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Digital Marketing & SEO</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="grid pt-3 pb-5 grid-cols-3">
          <h1 className="text-center col-span-2">Popular Blogs</h1>
          <section className="flex gap-2 h-[40px] my-2 justify-center items-center">
            {/* <input
              className="w-[210px] h-[40px] border-[1px] border-[#E6E6E6] rounded-md px-2  font-semibold"
              type="text"
              placeholder="Search Blog"
            /> */}
            <div class="widget widget-search">
              <form role="search" class="search-form">
                <input type="text" class="form-control" placeholder="Search" />
                <button type="submit" class="search-submit">
                  <i class="fa fa-search"></i>
                </button>
              </form>
            </div>
            <Link to="/editor2">
              <p className="flex font-mono bg-[#015ABD] text-white my-2 px-4 cursor-pointer py-1 rounded-full font-semibold gap-2 items-center">
                <TfiWrite></TfiWrite>
                <span>Write</span>
              </p>
            </Link>
          </section>
        </div>

        <div className="grid px-6 grid-cols-3 gap-3">
          {blogs.slice(1).map((b, index) => (
            <>
              <BlogCart {...b} index={index}></BlogCart>
            </>
          ))}
        </div>
      </section>
      {/* <!--  Course category End --> */}
      <div class="widget widget_latest_post">
        <h4 class="widget-title">Latest Posts</h4>
        <div class="recent-posts">
          <div class="single-latest-post">
            <div class="widget-thumb">
              <a href="blog-single.html">
                <img src={blogsm3} alt="" class="img-fluid" />
              </a>
            </div>
            <div class="widget-content">
              <h5>
                {" "}
                <a href="blog-single.html">
                  Custom Platform for an Audit Insurance
                </a>
              </h5>
              <span>
                <i class="fa fa-calendar-times"></i>10 april 2021
              </span>
            </div>
          </div>

          <div class="single-latest-post">
            <div class="widget-thumb">
              <a href="blog-single.html">
                <img src={blogsm2} alt="" class="img-fluid" />
              </a>
            </div>
            <div class="widget-content">
              <h5>
                {" "}
                <a href="blog-single.html">
                  World’s most famous app developers
                </a>
              </h5>
              <span>
                <i class="fa fa-calendar-times"></i>10 april 2021
              </span>
            </div>
          </div>
          <div class="single-latest-post">
            <div class="widget-thumb">
              <a href="blog-single.html">
                <img src={blogsm3} alt="" class="img-fluid" />
              </a>
            </div>
            <div class="widget-content">
              <h5>
                {" "}
                <a href="blog-single.html">Be a top rated marketer</a>
              </h5>
              <span>
                <i class="fa fa-calendar-times"></i>10 april 2021
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="widget widget_categories">
        <h4 class="widget-title">Categories</h4>
        <ul>
          <li class="cat-item">
            <a href="#">Web Design</a>(4)
          </li>
          <li class="cat-item">
            <a href="#">Wordpress</a>(14)
          </li>
          <li class="cat-item">
            <a href="#">Marketing</a>(24)
          </li>
          <li class="cat-item">
            <a href="#">Design & dev</a>(6)
          </li>
        </ul>
      </div>

      <div class="widget widget_tag_cloud">
        <h4 class="widget-title">Tags</h4>
        <a href="#">Design</a>
        <a href="#">Development</a>
        <a href="#">UX</a>
        <a href="#">Marketing</a>
        <a href="#">Tips</a>
        <a href="#">Tricks</a>
        <a href="#">Ui</a>
        <a href="#">Free</a>
        <a href="#">Wordpress</a>
        <a href="#">bootstrap</a>
        <a href="#">Tutorial</a>
        <a href="#">Html</a>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Blog;
