import React from "react";
import Header from "../../Shared/Header/Header";
import courseAuhtor from "./../../../../Images/course/course-author.png";
import Courseheader from "./SIngleCourseComponent/Courseheader";
import courseImage02 from "./../../../../Images/course/img_02.jpg";
import Video from "./SIngleCourseComponent/Video";
import CourseSidebar from "./SIngleCourseComponent/CourseSidebar";
import CourseNavbar from "./SIngleCourseComponent/CourseNavbar";
const SingleCourseLayout = () => {
  return (
    <div id="top-header">
      <Header></Header>
      {/* Banner part start */}
      <section className="course-page-header page-header-2">
        <div className="container">
          <div className="row">
            <Courseheader
              author="Madge Alvarez"
              authorImage={courseAuhtor}
              courseTitle="Mastering PHP from zero to hero"
              rating="19"
              totalStudents="11"
            ></Courseheader>
            <Video
              bgImage={courseImage02}
              videoLink="https://www.youtube.com/watch?v=OvvuTXds5Tc"
            ></Video>
          </div>
        </div>
      </section>
      {/* Banner part ends */}
      {/* Lower part starts */}
      <section className="tutori-course-single tutori-course-layout-3 page-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="tutori-course-content">
                <CourseNavbar></CourseNavbar>
              </div>
            </div>
            <CourseSidebar></CourseSidebar>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SingleCourseLayout;
