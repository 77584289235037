import React from "react";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import { useForm, ValidationError } from "@formspree/react";
import swal from "sweetalert";
import spinner from "./../../../Images/Spinner1.1.gif";
const Contact = () => {
  const [state, handleSubmit] = useForm("myyajpdp");

  if (state.succeeded) {
    console.log(state);
    swal("", "Message Sent Successfully", "success");
  }
  return (
    <div id="top-header">
      <Header></Header>
      {/* <!--====== Header End ======--> */}
      <section class="page-header">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-8">
              <div class="title-block">
                <h1>Contact Us</h1>
                <ul class="header-bradcrumb justify-content-center">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li class="active" aria-current="page">
                    Contact
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Contact section start --> */}
      <section class="contact section-padding">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-xl-4 col-lg-5">
              <div class="contact-info-wrapper mb-5 mb-lg-0">
                <h3>
                  what's your story?
                  <span>Get in touch</span>
                </h3>
                <p> Feel Free to contact with us</p>

                <div class="contact-item">
                  <i class="fa fa-envelope"></i>
                  <p>
                    <b>binaryclassroom01@gmail.com</b>
                  </p>
                </div>

                <div class="contact-item">
                  <i class="fa fa-phone-alt"></i>
                  <h5>01715225314</h5>
                </div>

                <div class="contact-item">
                  <i class="fa fa-map-marker"></i>
                  <h5>Dhaka,Bangladesh </h5>
                </div>
              </div>
            </div>

            <div class="col-xl-7 col-lg-6">
              <form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-12">
                    <div
                      class="alert alert-success contact__msg"
                      style={{ display: "none" }}
                      role="alert"
                    >
                      Your message was sent successfully.
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        className="w-[100%] border-[1px] h-[50px] px-3 bg-gray-100"
                        id="name"
                        type="subject"
                        placeholder="Your Name"
                        name="name"
                      />
                      <ValidationError
                        prefix="name"
                        field="name"
                        errors={state.errors}
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        className="w-[100%] border-[1px] h-[50px] px-3 bg-gray-100"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                      />
                      <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <input
                        className="w-[100%] border-[1px] h-[50px] px-3 bg-gray-100"
                        id="subject"
                        type="subject"
                        placeholder="Subject"
                        name="subject"
                      />
                      <ValidationError
                        prefix="subject"
                        field="subject"
                        errors={state.errors}
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <textarea
                        className="w-[100%] border-[1px] h-[200px] px-3 bg-gray-100"
                        id="message"
                        name="message"
                        placeholder="Enter Your Query"
                      />
                      <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="text-center group h-[50px]">
                    <button
                      className="flex justify-center items-center px-5 py-2 w-[100%] bg-slate-600 text-white"
                      type="submit"
                      disabled={state.submitting}
                    >
                      Submit
                      {state.submitting ? (
                        <img
                          className="h-[50px] group-hover:bg-black"
                          src={spinner}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact section End -->

    <!-- Map section start --> */}
      <section class="map">
        <div id="map"></div>
      </section>
      {/* <!-- Map section End --> */}
      <Footer></Footer>
    </div>
  );
};

export default Contact;
