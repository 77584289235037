import React, { useState } from "react";
import {
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
  useSignOut,
} from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import auth from "../../../firebase.config";
import GetFirebaseError from "../../Hooks/GetFirebaseError";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import spinner from "./../../../Images/Spinner.gif";
import swal from "sweetalert";
import { async } from "@firebase/util";
export default function Register() {
  const navigate = useNavigate();
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  const [sendEmailVerification, sending, error2] =
    useSendEmailVerification(auth);
  const [email, setEmail] = useState("");
  const [signOut, loading1, error1] = useSignOut(auth);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  if (error) {
    let msg = GetFirebaseError(error);
    swal("", `${msg}`, "error");
  }

  if (user && !loading1 && !sending) {
    // console.log(user.email);
    swal("", "Registration Successful ,Plz Check Your Email!", "success").then(
      (val) => {
        //signOut();
        console.log(val);
        //   navigate("/login");
        const out = async () => {
          const send = await sendEmailVerification(email);
          let suc;
          if (send) {
            suc = await signOut();
          }

          //  console.log(error2);
          if (suc && send) navigate("/login");
        };
        out();
        //  console.log("hello");
      }
    );
  }
  const onSubmit = (data) => {
    setEmail(data.email);
    if (data.password === data.confirmPassword)
      createUserWithEmailAndPassword(data.email, data.password);
    else {
      swal("", "Passwords don't Match", "error");
    }
  };

  return (
    <div id="top-header">
      <Header></Header>
      {/* Header ends here */}

      <section class="woocommerce single page-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8 col-xl-7">
              <div class="signup-form">
                <div class="form-header">
                  <h2 class="font-weight-bold mb-3">Sign Up</h2>
                  <p class="woocommerce-register">
                    Already have an account?{" "}
                    <Link to="/login" class="text-decoration-underline">
                      Log in
                    </Link>
                  </p>
                </div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  class="woocommerce-form woocommerce-form-register register"
                >
                  <div class="row">
                    <div class="col-xl-6">
                      <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                        <label>
                          First Name&nbsp;<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          {...register("firstName", { required: true })}
                          className="w-[100%]"
                          placeholder="First Name"
                        />{" "}
                        {errors.firstName && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </p>
                    </div>
                    <div class="col-xl-6">
                      <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                        <label>
                          Last Name&nbsp;<span class="required">*</span>
                        </label>
                        <input
                          {...register("lastName", { required: true })}
                          type="text"
                          className="w-[100%]"
                          placeholder="Last Name"
                        />
                        {errors.lastName && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </p>
                    </div>
                    <div class="col-xl-6">
                      <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                        <label>
                          User Name&nbsp;<span class="required">*</span>
                        </label>
                        <input
                          {...register("userName", { required: true })}
                          type="text"
                          className="w-[100%] "
                          placeholder="Username"
                        />

                        {errors.userName && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </p>
                    </div>

                    <div class="col-xl-6">
                      <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                        <label>
                          Email&nbsp;<span class="required">*</span>
                        </label>
                        <input
                          type="email"
                          className="w-[100%] "
                          {...register("email", { required: true })}
                          placeholder=" Email"
                        />
                        {errors.email && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </p>
                    </div>

                    <div class="col-xl-6">
                      <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                        <label>
                          Password&nbsp;<span class="required">*</span>
                        </label>
                        <input
                          {...register("password", { required: true })}
                          type="password"
                          className="w-[100%] "
                          placeholder="Password"
                        />
                        {errors.password && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </p>
                    </div>
                    <div class="col-xl-6">
                      <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                        <label>
                          Re-Enter Password&nbsp;<span class="required">*</span>
                        </label>
                        <input
                          {...register("confirmPassword", { required: true })}
                          type="password"
                          className="w-[100%] "
                          placeholder="Re-Enter Password"
                        />
                        {errors.confirmPassword && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </p>
                    </div>
                    <span id="Message"></span>
                    <div class="col-xl-12">
                      <div class="d-flex align-items-center justify-content-between py-2">
                        <p class="form-row">
                          <label class="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__policy">
                            <input
                              class="woocommerce-form__input  accent-slate-600 woocommerce-form__input-checkbox"
                              {...register("accept", {
                                required: true,
                              })}
                              type="checkbox"
                            />{" "}
                            <span>Accept the Terms and Privacy Policy</span>
                            {errors.accept && (
                              <span className="text-red-500">
                                This field is required
                              </span>
                            )}
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>

                  <p class="woocommerce-FormRow form-row flex h-[50px]">
                    <input
                      className="w-[100%]"
                      value="Register"
                      type="submit"
                    />

                    {loading ? <img src={spinner} alt="" /> : ""}
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--shop register end--> */}
      {/* Footer starts */}
      <Footer padding={false}></Footer>
      {/* Footer ends */}
    </div>
  );
}
