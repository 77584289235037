import React from "react";
//Importing images
import logopik from "./../../../Images/logo.png";
import bannerImg from "./../../../Images/banner/banner_img.jpg";
import bannerImg09 from "./../../../Images/banner/img_9.png";
import bannerIllustration from "./../../../Images/banner/illustration.png";
import icon6 from "./../../../Images/icon/icon6.png";
import icon1 from "./../../../Images/icon/icon1.png";
import icon2 from "./../../../Images/icon/icon2.png";
import icon3 from "./../../../Images/icon/icon3.png";
import icon4 from "./../../../Images/icon/icon4.png";
import icon5 from "./../../../Images/icon/icon5.png";
import course2 from "./../../../Images/course/course-2.jpg";
import courseImg01 from "./../../../Images/course/img_01.jpg";
import courseImg02 from "./../../../Images/course/img_02.jpg";
import courseImg03 from "./../../../Images/course/img_03.jpg";
import courseImg04 from "./../../../Images/course/img_04.jpg";
import courseImg06 from "./../../../Images/course/img_06.jpg";
import courseImg05 from "./../../../Images/course/img_05.jpg";
import office01 from "./../../../Images/bg/cta-bg.jpg";
import avata01 from "./../../../Images/clients/testimonial-avata-01.jpg";
import avata02 from "./../../../Images/clients/testimonial-avata-02.jpg";
import avata03 from "./../../../Images/clients/testimonial-avata-03.jpg";
import avata04 from "./../../../Images/clients/testimonial-avata-04.jpg";
import team1 from "./../../../Images/team/team-1.jpg";
import team2 from "./../../../Images/team/team-2.jpg";
import team3 from "./../../../Images/team/team-3.jpg";
import team4 from "./../../../Images/team/team-4.jpg";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
const Home4 = () => {
  return (
    <div id="top-header">
      <Header></Header>
      {/* <!-- Banner Section Start --> */}
      <section class="banner banner-style-1">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-12 col-xl-6 col-lg-6">
              <div class="banner-content">
                <h1>Distant learning for further expansion </h1>
                <p>
                  The ultimate planning solution for people who want to reach
                  their personal goals Effortless comfortable.
                </p>

                <div class="banner-form me-5">
                  <form action="#" class="form">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="What do you want to learn?"
                    />
                    <a href="#">
                      {" "}
                      Search<i class="far fa-search"></i>
                    </a>
                  </form>
                </div>
                <div class="category-name">
                  <span>Popular:</span>
                  <a href="#">Design ,</a>
                  <a href="#">Development ,</a>
                  <a href="#">Marketing ,</a>
                  <a href="#">Affiliate</a>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-xl-6 col-lg-6">
              <div class="banner-img-round mt-5 mt-lg-0">
                <img src={bannerImg} alt="" class="img-fluid" />
              </div>
            </div>
          </div>
          {/* <!-- / .row --> */}
        </div>
        {/* <!-- / .container --> */}
      </section>
      {/* <!-- Banner Section End -->

<!--  Feature Intro Start --> */}
      <section class="features-intro">
        <div class="container">
          <div class="feature-inner">
            <div class="row">
              <div class="col-xl-4 col-lg-4 ">
                <div class="feature-item feature-style-left">
                  <div class="feature-icon icon-bg-1">
                    <i class="fad fa-users"></i>
                  </div>
                  <div class="feature-text">
                    <h4>Get Certificate</h4>
                    <p>Lorem ipsum dolor seat ameat dui too consecteture</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4">
                <div class="feature-item feature-style-left">
                  <div class="feature-icon icon-bg-2">
                    <i class="far fa-file-certificate"></i>
                  </div>
                  <div class="feature-text">
                    <h4>Skilled Instructors</h4>
                    <p>Lorem ipsum dolor seat ameat dui too consecteture</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4">
                <div class="feature-item feature-style-left">
                  <div class="feature-icon icon-bg-3">
                    <i class="fa fa-video"></i>
                  </div>
                  <div class="feature-text">
                    <h4>Online Classes</h4>
                    <p>Lorem ipsum dolor seat ameat dui too consecteture</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--  Feature Intro End -->




<!--  Course category --> */}
      <section class="section-padding">
        <div class="container-fluid container-grid">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <div class="heading mb-50 text-center">
                <span class="subheading">Top Categories</span>
                <h2>Browse Courses By Category</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2 mb-20">
                <div class="course-cat-icon">
                  <img src={icon1} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Data Science & Analytics</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon6} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Web Development</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon3} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Artificial Intellegence</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon2} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Digital marketing</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2 ">
                <div class="course-cat-icon">
                  <img src={icon3} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Artificial Intellegence</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon6} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Web Development</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon1} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Data Science & Analytics</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon1} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Health & Fitness</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon1} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Marketing</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon6} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Web Development</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2">
                <div class="course-cat-icon">
                  <img src={icon6} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Photography</a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="single-course-category style-2 ">
                <div class="course-cat-icon">
                  <img src={icon3} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Data Science</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--  Course category End -->
<!-- Counter Section start --> */}
      <section class="counter-section-2">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-5 pr-xl-5 col-lg-8">
              <div class="section-heading mb-5 mb-xl-0 text-center text-xl-start">
                <span class="subheading">
                  10,000+ Trusted Clients All Over The World
                </span>
                <h2 class="font-lg">
                  Some reasons why Start Your Online Learning with Us
                </h2>
              </div>
            </div>

            <div class="col-xl-7">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-1 mb-4 mb-lg-0">
                    <i class="flaticon-man"></i>
                    <div class="count">
                      <span class="counter h2">2</span>
                      <span>k</span>
                    </div>
                    <p>Students</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-2 mb-4 mb-lg-0">
                    <i class="flaticon-infographic"></i>
                    <div class="count">
                      <span class="counter h2">120</span>
                      <span>+</span>
                    </div>
                    <p>Online Courses</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-3">
                    <i class="flaticon-satisfaction"></i>
                    <div class="count">
                      <span class="counter h2">100</span>
                      <span>%</span>
                    </div>
                    <p>Satisfaction</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- COunter Section End -->



    <!--course section start--> */}
      <section class="section-padding course-filter-section">
        <div class="container">
          <div class="row align-items-center justify-content-between mb-30">
            <div class="col-xl-12">
              <div class="heading text-center mb-40">
                <span class="subheading">Course Trending</span>
                <h2 class="font-lg">Explore popular courses</h2>
              </div>

              <div class="filter-wrap text-center">
                <ul class="course-filter ">
                  <li class="active">
                    <a href="#" data-filter="*">
                      {" "}
                      All
                    </a>
                  </li>
                  <li>
                    <a href="#" data-filter=".cat1">
                      printing
                    </a>
                  </li>
                  <li>
                    <a href="#" data-filter=".cat2">
                      Web
                    </a>
                  </li>
                  <li>
                    <a href="#" data-filter=".cat3">
                      illustration
                    </a>
                  </li>
                  <li>
                    <a href="#" data-filter=".cat4">
                      media
                    </a>
                  </li>
                  <li>
                    <a href="#" data-filter=".cat5">
                      crafts
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid container-padding">
          <div class="row course-gallery justify-content-center">
            <div class="course-item cat1 cat5 col-lg-3 col-md-6 col-sm-6">
              <div class=" course-style-5 bg-white">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg05} alt="" class="img-fluid" />
                    <div class="course-price">$300</div>
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta meta-style-1">
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                    <span class="label">Beginner</span>
                  </div>
                  <h4>
                    {" "}
                    <a href="#">
                      Emotional Intelligence at Work: Learn Emotions
                    </a>{" "}
                  </h4>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <span class="students">
                      <i class="far fa-user-alt me-2"></i>51 Students
                    </span>
                    <a href="#" class="rounded-btn">
                      <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat2 cat4 col-lg-3 col-md-6 col-sm-6">
              <div class=" course-style-5 bg-white">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg03} alt="" class="img-fluid" />
                    <div class="course-price">$300</div>
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta meta-style-1">
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                    <span class="label">Intermediate</span>
                  </div>
                  <h4>
                    {" "}
                    <a href="#">
                      SQL-Data Analysis: Crash Course For Masters
                    </a>{" "}
                  </h4>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <span class="students">
                      <i class="far fa-user-alt me-2"></i>51 Students
                    </span>
                    <a href="#" class="rounded-btn">
                      <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat3 cat2 col-lg-3 col-md-6 col-sm-6">
              <div class=" course-style-5 bg-white">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg02} alt="" class="img-fluid" />
                    <div class="course-price">Free</div>
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta meta-style-1">
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                    <span class="label">Intermediate</span>
                  </div>
                  <h4>
                    {" "}
                    <a href="#">Learn How to Start an Amazon FBA Store</a>{" "}
                  </h4>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <span class="students">
                      <i class="far fa-user-alt me-2"></i>51 Students
                    </span>
                    <a href="#" class="rounded-btn">
                      <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat5 cat1 col-lg-3 col-md-6 col-sm-6">
              <div class=" course-style-5 bg-white">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg01} alt="" class="img-fluid" />
                    <div class="course-price">$300</div>
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta meta-style-1">
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                    <span class="label">Expert</span>
                  </div>
                  <h4>
                    {" "}
                    <a href="#">
                      Writing With Flair: Become a Top Rated Writer
                    </a>{" "}
                  </h4>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <span class="students">
                      <i class="far fa-user-alt me-2"></i>51 Students
                    </span>
                    <a href="#" class="rounded-btn">
                      <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat3 cat2 col-lg-3 col-md-6 col-sm-6">
              <div class=" course-style-5 bg-white">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg03} alt="" class="img-fluid" />
                    <div class="course-price">$240</div>
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta meta-style-1">
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                    <span class="label">Intermediate</span>
                  </div>
                  <h4>
                    {" "}
                    <a href="#">
                      Cinematography Course: Shoot Better Video{" "}
                    </a>{" "}
                  </h4>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <span class="students">
                      <i class="far fa-user-alt me-2"></i>51 Students
                    </span>
                    <a href="#" class="rounded-btn">
                      <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat3 cat2 col-lg-3 col-md-6 col-sm-6">
              <div class=" course-style-5 bg-white">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg01} alt="" class="img-fluid" />
                    <div class="course-price">$300</div>
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta meta-style-1">
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                    <span class="label">Intermediate</span>
                  </div>
                  <h4>
                    {" "}
                    <a href="#">
                      Data Competitive Strategy law & Organization{" "}
                    </a>{" "}
                  </h4>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <span class="students">
                      <i class="far fa-user-alt me-2"></i>51 Students
                    </span>
                    <a href="#" class="rounded-btn">
                      <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat3 cat2 col-lg-3 col-md-6 col-sm-6">
              <div class=" course-style-5 bg-white">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg06} alt="" class="img-fluid" />
                    <div class="course-price">$460</div>
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta meta-style-1">
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                    <span class="label">Expert</span>
                  </div>
                  <h4>
                    {" "}
                    <a href="#">
                      Adobe Lightroom Classic : Photo Editing Masterclass
                    </a>{" "}
                  </h4>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <span class="students">
                      <i class="far fa-user-alt me-2"></i>51 Students
                    </span>
                    <a href="#" class="rounded-btn">
                      <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat3 cat2 col-lg-3 col-md-6 col-sm-6">
              <div class=" course-style-5 bg-white">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg04} alt="" class="img-fluid" />
                    <div class="course-price">$300</div>
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta meta-style-1">
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                    <span class="label">Intermediate</span>
                  </div>
                  <h4>
                    {" "}
                    <a href="#">
                      The Complete Salesforce Classic Certification{" "}
                    </a>{" "}
                  </h4>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <span class="students">
                      <i class="far fa-user-alt me-2"></i>51 Students
                    </span>
                    <a href="#" class="rounded-btn">
                      <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--course--> */}
      </section>
      {/* <!--course section end-->


<!-- Section cta start --> */}
      <section class="cta-intro section-padding-btm">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-8 col-md-12">
              <div class="cta-inner-wrapper">
                <div class="row justify-content-between align-items-center">
                  <div class="col-xl-8 col-lg-8 col-md-6">
                    <div class="section-heading mb-3 mb-lg-0 text-center text-md-start">
                      <span class="text-color">Let Us Help</span>
                      <h4>Finding Your Right Courses</h4>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="text-center text-md-end">
                      <a href="#" class="btn btn-main rounded">
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section cta End -->
<!-- Work Process Section Start --> */}
      <section class="work-process-section">
        <div class="container">
          <div class="row mb-70 justify-content-between">
            <div class="col-xl-5">
              <div class="section-heading text-center text-lg-start mb-4 mb-xl-0">
                <span class="subheading">How to Start</span>
                <h2 class="font-lg">4 steps start your journey with us</h2>
              </div>
            </div>
            <div class="col-xl-6">
              <p class="text-center text-lg-start">
                Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                venenatis vestibulum. Etiam porta sem malesuada magna mollis
                euismod. Nullam id dolor id nibh ultricies vehicula ut id elit.
                Nullam quis risus eget urna mollis.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-3 col-lg-6 col-md-6">
              <div class="step-wrapper">
                <div class="step-item ">
                  <div class="step-number bg-1">01</div>
                  <div class="step-text">
                    <h5>Signup with all info</h5>
                    <p>
                      Lorem ipsum dolor seat ameat dui too consecteture elite
                      adipaising.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3  col-lg-6 col-md-6">
              <div class="step-wrapper">
                <div class="step-item">
                  <div class="step-number bg-2">02</div>
                  <div class="step-text">
                    <h5>Take your Admission</h5>
                    <p>
                      Lorem ipsum dolor seat ameat dui too consecteture elite
                      adipaising.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3  col-lg-6 col-md-6">
              <div class="step-wrapper">
                <div class="step-item">
                  <div class="step-number bg-3">03</div>
                  <div class="step-text">
                    <h5>Learn from online </h5>
                    <p>
                      Lorem ipsum dolor seat ameat dui too consecteture elite
                      adipaising.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3  col-lg-6 col-md-6">
              <div class="step-wrapper">
                <div class="step-item">
                  <div class="step-number bg-2">04</div>
                  <div class="step-text">
                    <h5>Get certificate</h5>
                    <p>
                      Lorem ipsum dolor seat ameat dui too consecteture elite
                      adipaising.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Work Process Section End -->
<!-- Testimonial section start --> */}
      <section class="testimonial section-padding">
        <div class="container-fluid container-grid">
          <div class="row justify-content-center">
            <div class="col-xl-6">
              <div class="section-heading text-center mb-50">
                <span class="subheading">Students Feedback</span>
                <h2 class="font-lg">Our Students Says</h2>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-12 col-xl-12">
              <div class="testimonials-slides-3 owl-carousel owl-theme">
                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata01} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Cory Zamora</h4>
                        <span class="meta">Marketing Specialist</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text  mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata02} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Jackie Sanders</h4>
                        <span class="meta">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text  mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata03} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Nikolas Brooten</h4>
                        <span class="meta">Sales Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata04} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Terry Ambady</h4>
                        <span class="meta">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata03} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Nikolas Brooten</h4>
                        <span class="meta">Sales Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Testimonial section End -->

<!-- InstructorsSection Start --> */}
      <section class="be-instructor section-padding-btm">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6">
              <img src={bannerIllustration} alt="" class="img-fluid" />
            </div>

            <div class="col-xl-6 col-lg-6">
              <div class="section-heading mt-4 mt-lg-0">
                <span class="subheading">Start Today</span>
                <h2 class="font-lg mb-20">Become an instructor </h2>
                <p class="mb-20">
                  Do you want to be an instructor? Do you want to share your
                  knowledge with everyone? If you have any skill then you can
                  easily share your knowledge online or offline through iLive
                  platform & make money.
                </p>
                <p class="mb-30">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit
                  adipisci corporis soluta facilis eum facere nisi dolorem
                  quisquam amet deleniti!
                </p>

                <a href="#" class="btn btn-main rounded">
                  Start Teaching today
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Instructors Section End -->

<!-- Footer section start --> */}
      <Footer padding={false}></Footer>
      {/* <!-- Footer section End --> */}
    </div>
  );
};

export default Home4;
