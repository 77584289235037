import React from "react";

const CoursesSearchBar = () => {
  return (
    <div class="course-top-wrap mb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <p>Showing 1-6 of 8 results</p>
          </div>

          <div class="col-lg-4">
            <div class="topbar-search">
              <form method="get" action="#">
                <input
                  type="text"
                  placeholder="Search our courses"
                  class="form-control"
                />
                <label>
                  <i class="fa fa-search"></i>
                </label>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesSearchBar;
