import React from "react";
//Importing images
import logopik from "./../../../Images/logo.png";
import bannerImg from "./../../../Images/banner/banner_img.jpg";
import bannerImg09 from "./../../../Images/banner/img_9.png";
import icon6 from "./../../../Images/icon/icon6.png";
import icon1 from "./../../../Images/icon/icon1.png";
import icon2 from "./../../../Images/icon/icon2.png";
import icon3 from "./../../../Images/icon/icon3.png";
import icon4 from "./../../../Images/icon/icon4.png";
import icon5 from "./../../../Images/icon/icon5.png";
import course2 from "./../../../Images/course/course-2.jpg";
import courseImg01 from "./../../../Images/course/img_01.jpg";
import courseImg02 from "./../../../Images/course/img_02.jpg";
import courseImg03 from "./../../../Images/course/img_03.jpg";
import courseImg06 from "./../../../Images/course/img_06.jpg";
import office01 from "./../../../Images/bg/cta-bg.jpg";
import avata01 from "./../../../Images/clients/testimonial-avata-01.jpg";
import avata02 from "./../../../Images/clients/testimonial-avata-02.jpg";
import avata03 from "./../../../Images/clients/testimonial-avata-03.jpg";
import avata04 from "./../../../Images/clients/testimonial-avata-04.jpg";
import team1 from "./../../../Images/team/team-1.jpg";
import team2 from "./../../../Images/team/team-2.jpg";
import team3 from "./../../../Images/team/team-3.jpg";
import team4 from "./../../../Images/team/team-4.jpg";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
const Home3 = () => {
  return (
    <div id="top-header">
      <header class="header-style-3">
        <div class="header-topbar topbar-noticebar">
          <div class="container-fluid container-padding">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8">
                <div class="header-notice text-center">
                  Keep learning with free resources during COVID-19.{" "}
                  <a href="#">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="menu-wrapper">
          <div class="header-mid">
            <div class="container">
              <div class="row align-items-center justify-content-between">
                <div class="col-xl-4 col-lg-4">
                  <div class="header-socials d-none d-lg-block">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#">
                          <i class="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#">
                          <i class="fab fa-pinterest"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#">
                          <i class="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-5 col-xl-3 col-lg-3 col-sm-4 col-md-3">
                  <div class="site-logo">
                    <Link to="/home">
                      <img src={logopik} alt="" class="img-fluid" />
                    </Link>
                  </div>
                </div>

                <div class="col-7 col-xl-4 col-lg-4 col-sm-8">
                  <div class="offcanvas-icon d-block d-lg-none text-end">
                    <a href="#" class="nav-toggler">
                      <i class="fal fa-bars"></i>
                    </a>
                  </div>

                  <div class="float-end d-none d-lg-block">
                    <div class="header-info d-flex align-items-center">
                      <div class="header-search header_search_btn">
                        <a href="#">
                          <i class="fa fa-search"></i>
                        </a>
                      </div>

                      <div class="header-user ms-4">
                        <Link to="/login" id="user-icon">
                          <i class="fa fa-user"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="header-navbar menu-center style-1 ">
            <div class="container">
              <nav class="site-navbar">
                <ul class="primary-menu">
                  <li class="current">
                    <Link to="/home">Home</Link>
                    <ul class="submenu">
                      <li>
                        <Link to="/home">Home One</Link>
                      </li>
                      <li>
                        <Link to="/home2">Home Two</Link>
                      </li>
                      <li>
                        <Link to="/home3">Home Three</Link>
                      </li>
                      <li>
                        <Link to="/home4">Home Four</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>

                  <li>
                    <a href="courses.html">Courses</a>
                    <ul class="submenu">
                      <li>
                        <a href="courses.html">Courses</a>
                      </li>
                      <li>
                        <a href="courses-2.html">Course Grid 2 </a>
                      </li>
                      <li>
                        <a href="courses-3.html">Course Grid 3</a>
                      </li>
                      <li>
                        <a href="courses-4.html">Course Grid 4</a>
                      </li>
                      <li>
                        <a href="courses-5-list.html">Course List</a>
                      </li>
                      <li>
                        <a href="#">Single Layout</a>
                        <ul class="submenu">
                          <li>
                            <a href="course-single.html">Course Single 1</a>
                          </li>
                          <li>
                            <a href="course-single-2.html">Course Single 2</a>
                          </li>
                          <li>
                            <a href="course-single-3.html">Course Single 3</a>
                          </li>
                          <li>
                            <a href="course-single-4.html">Course Single 4</a>
                          </li>
                          <li>
                            <a href="course-single-5.html">Course Single 5</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="#">Pages</a>
                    <ul class="submenu">
                      <li>
                        <Link to="/instructors">Instructors</Link>
                      </li>
                      <li>
                        <Link to="/cart">Cart</Link>
                      </li>
                      <li>
                        <Link to="/checkout">Checkout</Link>
                      </li>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      <li>
                        <a href="Register.html">Register</a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/blog">Blog</Link>
                    <ul class="submenu">
                      <li>
                        <Link to="/blog-grid">Blog</Link>
                      </li>
                      <li>
                        <Link to="/blogDetails">Blog Details</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>

                <a href="#" class="nav-close">
                  <i class="fal fa-times"></i>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!--====== Header End ======--> */}

      <div class="page_search_box">
        <div class="search_close">
          <i class="fa fa-times"></i>
        </div>
        <form class="border-bottom" action="#">
          <input class="border-0" placeholder="Search Courses..." type="text" />
          <button type="submit">
            <i class="fa fa-search"></i>
          </button>
        </form>
      </div>

      {/* <!-- Banner Section Start --> */}
      <section class="banner-style-3 banner-padding">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-12 col-xl-7 col-lg-10">
              <div class="banner-content text-center">
                <span class="subheading">start a successful career</span>
                <h1>Explore variety of quality courses and gain new skills </h1>
                <p>
                  The ultimate planning solution for people who want to reach
                  their personal goals Effortless comfortable.
                </p>

                <a href="#" class="btn btn-main rounded">
                  Our courses
                </a>
              </div>
            </div>
          </div>
          {/* <!-- / .row --> */}
        </div>
        {/* <!-- / .container --> */}
      </section>
      {/* <!-- Banner Section End -->

<!--  Course category --> */}
      <section class="category-section section-padding-top">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <div class="section-heading mb-70 text-center">
                <span class="subheading">Course Category</span>
                <h2 class="font-lg">Browse Courses By Category</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl col-sm-6 col-lg-4">
              <div class="single-course-category style-1 bg-shade">
                <div class="course-cat-icon">
                  <img src={icon1} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Data Science & Analytics</a>
                  </h4>

                  <p class="course-count">5 courses</p>
                </div>
              </div>
            </div>

            <div class="col-xl col-sm-6 col-lg-4">
              <div class="single-course-category style-1 bg-shade">
                <div class="course-cat-icon">
                  <img src={icon2} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Artificial Intellegence</a>
                  </h4>

                  <p class="course-count">3 courses</p>
                </div>
              </div>
            </div>

            <div class="col-xl col-sm-6 col-lg-4">
              <div class="single-course-category style-1 bg-shade">
                <div class="course-cat-icon">
                  <img src={icon3} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Algebra Math calculation</a>
                  </h4>

                  <p class="course-count">7 courses</p>
                </div>
              </div>
            </div>

            <div class="col-xl col-sm-6 col-lg-4">
              <div class="single-course-category style-1 bg-shade">
                <div class="course-cat-icon">
                  <img src={icon4} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">Web Development</a>
                  </h4>

                  <p class="course-count">10 courses</p>
                </div>
              </div>
            </div>

            <div class="col-xl col-sm-6 col-lg-4">
              <div class="single-course-category style-1 bg-shade">
                <div class="course-cat-icon">
                  <img src={icon6} alt="" class="img-fluid" />
                </div>
                <div class="course-cat-content">
                  <h4 class="course-cat-title">
                    <a href="#">DIgital Marketing & SEO</a>
                  </h4>

                  <p class="course-count">10 courses</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--  Course category End -->
<!-- About Section Start --> */}
      <section class="about-3 section-padding">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-xl-5 col-lg-6">
              <div class="about-img">
                <img src={bannerImg09} alt="" class="img-fluid" />
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="about-content mt-5 mt-lg-0">
                <div class="heading mb-50">
                  <span class="subheading">10 years Glory of success</span>
                  <h2 class="font-lg">
                    Some reasons why Start Your Online Learning with Us
                  </h2>
                </div>

                <div class="about-features">
                  <div class="feature-item feature-style-left">
                    <div class="feature-icon icon-bg-3 icon-radius">
                      <i class="fa fa-video"></i>
                    </div>
                    <div class="feature-text">
                      <h4>Online Classes</h4>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Quidem veniam nulla inventore dolores fuga{" "}
                      </p>
                    </div>
                  </div>

                  <div class="feature-item feature-style-left">
                    <div class="feature-icon icon-bg-2 icon-radius">
                      <i class="far fa-file-certificate"></i>
                    </div>
                    <div class="feature-text">
                      <h4>Skilled Instructors</h4>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Quidem veniam nulla inventore dolores fuga{" "}
                      </p>
                    </div>
                  </div>

                  <div class="feature-item feature-style-left">
                    <div class="feature-icon icon-bg-1 icon-radius">
                      <i class="fad fa-users"></i>
                    </div>
                    <div class="feature-text">
                      <h4>Get Certificate</h4>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Quidem veniam nulla inventore dolores fuga{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About Section END -->
<!--  Course style 1 --> */}

      <section class="section-padding pt-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <div class="heading mb-50 text-center">
                <span class="subheading">Course Trending</span>
                <h2>Trending course over now</h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-4 col-md-6">
              <div class="course-grid course-style-3">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg01} alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta d-flex justify-content-between mb-20">
                    <span class="category">Design</span>
                    <span class="label">
                      <i class="fas fa-signal me-2"></i>Intermediate
                    </span>
                  </div>
                  <h3 class="course-title mb-20">
                    {" "}
                    <a href="#">
                      Data Competitive Strategy law & Organization{" "}
                    </a>{" "}
                  </h3>

                  <div class="course-meta-info">
                    <div class="d-flex align-items-center">
                      <div class="author me-3">
                        <img src={courseImg02} alt="" class="img-fluid" />
                        By <a href="#">Josephin</a>
                      </div>
                      <span class="students">
                        <i class="far fa-user-alt me-2"></i>51 Students
                      </span>
                    </div>
                  </div>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <div class="course-price">$300</div>
                    <a href="#" class="btn btn-main-outline btn-radius btn-sm">
                      Buy Now <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- COURSE END --> */}

            <div class="col-xl-4 col-lg-4 col-md-6">
              <div class="course-grid course-style-3">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg02} alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta d-flex justify-content-between mb-20">
                    <span class="category">Design</span>
                    <span class="label">
                      <i class="fas fa-signal me-2"></i>Intermediate
                    </span>
                  </div>
                  <h3 class="course-title mb-20">
                    {" "}
                    <a href="#">
                      Data Competitive Strategy law & Organization{" "}
                    </a>{" "}
                  </h3>

                  <div class="course-meta-info">
                    <div class="d-flex align-items-center">
                      <div class="author me-3">
                        <img src={courseImg02} alt="" class="img-fluid" />
                        By <a href="#">Josephin</a>
                      </div>
                      <span class="students">
                        <i class="far fa-user-alt me-2"></i>51 Students
                      </span>
                    </div>
                  </div>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <div class="course-price">$300</div>
                    <a href="#" class="btn btn-main-outline btn-radius btn-sm">
                      Buy Now <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- COURSE END --> */}

            <div class="col-xl-4 col-lg-4 col-md-6">
              <div class="course-grid course-style-3">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg03} alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="course-content">
                  <div class="course-meta d-flex justify-content-between mb-20">
                    <span class="category">Design</span>
                    <span class="label">
                      <i class="fas fa-signal me-2"></i>Intermediate
                    </span>
                  </div>
                  <h3 class="course-title mb-20">
                    {" "}
                    <a href="#">
                      Data Competitive Strategy law & Organization{" "}
                    </a>{" "}
                  </h3>

                  <div class="course-meta-info">
                    <div class="d-flex align-items-center">
                      <div class="author me-3">
                        <img src={course2} alt="" class="img-fluid" />
                        By <a href="#">Josephin</a>
                      </div>
                      <span class="students">
                        <i class="far fa-user-alt me-2"></i>51 Students
                      </span>
                    </div>
                  </div>

                  <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
                    <div class="course-price">$300</div>
                    <a href="#" class="btn btn-main-outline btn-radius btn-sm">
                      Buy Now <i class="fa fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- COURSE END --> */}
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="text-center">
                Want to help people learn, grow and achieve more in life?{" "}
                <a
                  href="#"
                  class="text-style2 text-decoration-underline fw-bold"
                >
                  Join now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--  Course style 1 End -->



<!-- Counter Section start --> */}
      <section class="counter-section-2 section-padding-btm">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-5 pr-xl-5 col-lg-8">
              <div class="section-heading mb-5 mb-xl-0 text-center text-xl-start">
                <span class="subheading">
                  10,000+ Trusted Clients All Over The World
                </span>
                <h2 class="font-lg">
                  Some reasons why Start Your Online Learning with Us
                </h2>
              </div>
            </div>

            <div class="col-xl-7">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-1 mb-4 mb-lg-0">
                    <i class="flaticon-man"></i>
                    <div class="count">
                      <span class="counter h2">2</span>
                      <span>k</span>
                    </div>
                    <p>Students</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-2 mb-4 mb-lg-0">
                    <i class="flaticon-infographic"></i>
                    <div class="count">
                      <span class="counter h2">120</span>
                      <span>+</span>
                    </div>
                    <p>Online Courses</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-3">
                    <i class="flaticon-satisfaction"></i>
                    <div class="count">
                      <span class="counter h2">100</span>
                      <span>%</span>
                    </div>
                    <p>Satisfaction</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- COunter Section End -->



<!-- Testimonial section start --> */}
      <section class="testimonial section-padding">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6">
              <div class="section-heading text-center mb-50">
                <span class="subheading">Students Feedback</span>
                <h2 class="font-lg">Our Students Says</h2>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-12 col-xl-12">
              <div class="testimonials-slides owl-carousel owl-theme">
                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata01} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Cory Zamora</h4>
                        <span class="meta">Marketing Specialist</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text  mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata02} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Jackie Sanders</h4>
                        <span class="meta">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text  mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata03} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Nikolas Brooten</h4>
                        <span class="meta">Sales Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata04} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Terry Ambady</h4>
                        <span class="meta">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata03} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Nikolas Brooten</h4>
                        <span class="meta">Sales Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Testimonial section End -->
<!-- InstructorsSection Start --> */}
      <section class="instructors section-padding-btm">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-6 pe-5">
              <div class="section-heading">
                <span class="subheading">Start Today</span>
                <h2 class="font-lg mb-20">Our Top Instructors</h2>
                <p class="mb-30">
                  Do you want to be an instructor? Do you want to share your
                  knowledge with everyone? If you have any skill then you can
                  easily share your knowledge online or offline through iLive
                  platform & make money.
                </p>

                <ul class="list-item mb-40">
                  <li>
                    <i class="fa fa-check"></i>
                    <h5>
                      Develop your skills Lorem ipsum dolor sit amet,
                      consectetur adipisicing elit.
                    </h5>
                  </li>
                  <li>
                    <i class="fa fa-check"></i>
                    <h5>
                      Share your knowledge Lorem ipsum dolor sit amet,
                      consectetur adipisicing elit.
                    </h5>
                  </li>
                  <li>
                    <i class="fa fa-check"></i>
                    <h5>
                      Earn from globally Lorem ipsum dolor sit amet, consectetur
                      adipisicing elit.
                    </h5>
                  </li>
                </ul>
                <a href="#" class="btn btn-main rounded">
                  Start Teaching today
                </a>
              </div>
            </div>

            <div class="col-xl-6">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="team-item team-item-2 mt-5">
                    <div class="team-img">
                      <img src={team4} alt="" class="img-fluid" />

                      <ul class="team-socials list-inline">
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="team-content">
                      <div class="team-info">
                        <h4>Harish Ham</h4>
                        <p>SEO Expert</p>
                      </div>

                      <div class="course-meta">
                        <span class="duration">
                          <i class="far fa-user-alt"></i>20 Students
                        </span>
                        <span class="lessons">
                          <i class="far fa-play-circle me-2"></i>2 Course
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="team-item team-item-2">
                    <div class="team-img">
                      <img src={team1} alt="" class="img-fluid" />
                      <ul class="team-socials list-inline">
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="team-content">
                      <div class="team-info">
                        <h4>John doe</h4>
                        <p>CEO, Developer</p>
                      </div>
                      <div class="course-meta">
                        <span class="duration">
                          <i class="far fa-user-alt"></i>20 Students
                        </span>
                        <span class="lessons">
                          <i class="far fa-play-circle me-2"></i>2 Course
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="team-item team-item-2">
                    <div class="team-img">
                      <img src={team2} alt="" class="img-fluid" />

                      <ul class="team-socials list-inline">
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="team-content">
                      <div class="team-info">
                        <h4>Mikel</h4>
                        <p>Web Developer</p>
                      </div>
                      <div class="course-meta">
                        <span class="duration">
                          <i class="far fa-user-alt"></i>20 Students
                        </span>
                        <span class="lessons">
                          <i class="far fa-play-circle me-2"></i>2 Course
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="team-item team-item-2">
                    <div class="team-img">
                      <img src={team3} alt="" class="img-fluid" />

                      <ul class="team-socials list-inline">
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="team-content">
                      <div class="team-info">
                        <h4>Johansam</h4>
                        <p>Marketer</p>
                      </div>

                      <div class="course-meta">
                        <span class="duration">
                          <i class="far fa-user-alt"></i>20 Students
                        </span>
                        <span class="lessons">
                          <i class="far fa-play-circle me-2"></i>2 Course
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Instructors Section End -->
<!-- CTA Sidebar start --> */}
      <section class="cta-5 mb--120">
        <div class="container">
          <div class="cta-inner2 ">
            <div class="row align-items-center justify-content-between">
              <div class="col-xl-6">
                <div class="cta-content ">
                  <span class="subheading">Not sure where to start?</span>
                  <h2 class="mb-4 mb-xl-0">
                    {" "}
                    Want to know Special Offers & Updates of new courses?
                  </h2>
                </div>
              </div>
              <div class="col-xl-6 text-lg-end">
                <div class="subscribe-form">
                  <form action="#" class="form">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="What do you want to learn?"
                    />
                    <a href="#" class="btn btn-main rounded">
                      {" "}
                      Search
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- CTA Sidebar end -->

<!-- Footer section start --> */}

      <Footer padding={true}></Footer>
      {/* <!-- Footer section End --> */}
    </div>
  );
};

export default Home3;
