import React from "react";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import CoursesLayout2 from "./CoursesLayout2";
import CoursesSearchBar from "./CoursesSearchBar";
import CoursesUpperBanner from "./CoursesUpperBanner";
//Images
import courseimg01 from "./../../../Images/course/img_01.jpg";
import courseimg02 from "./../../../Images/course/img_02.jpg";
import courseimg03 from "./../../../Images/course/img_03.jpg";
import courseimg04 from "./../../../Images/course/img_04.jpg";
import courseimg05 from "./../../../Images/course/img_05.jpg";
import courseimg06 from "./../../../Images/course/img_06.jpg";
import course2 from "./../../../Images/course/course-2.jpg";
const Courses2 = () => {
  return (
    <div id="top-header">
      <Header></Header>
      <CoursesUpperBanner pageTitle="Course Layout 2"></CoursesUpperBanner>
      <section class="section-padding page">
        <CoursesSearchBar></CoursesSearchBar>{" "}
        <div className="container">
          <div className="row justify-content-center">
            <CoursesLayout2
              author="Josephine"
              authorImage={course2}
              category="Design"
              level="Intermediate"
              courseImage={courseimg01}
              courseTitle="Data Competitive Strategy law & Organization"
              price="330"
              totalStudents="51"
            ></CoursesLayout2>
            <CoursesLayout2
              author="Josephine"
              authorImage={course2}
              category="Design"
              level="Intermediate"
              courseImage={courseimg02}
              courseTitle="Data Competitive Strategy law & Organization"
              price="330"
              totalStudents="51"
            ></CoursesLayout2>
            <CoursesLayout2
              author="Josephine"
              authorImage={course2}
              category="Design"
              level="Intermediate"
              courseImage={courseimg03}
              courseTitle="Data Competitive Strategy law & Organization"
              price="330"
              totalStudents="51"
            ></CoursesLayout2>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Courses2;
