import React, { useEffect, useState } from "react";
import logopik from "./../../../Images/logo.png";
import banner09 from "./../../../Images/banner/img_9.png";
import team4 from "./../../../Images/team/team-4.jpg";
import team1 from "./../../../Images/team/team-1.jpg";
import team2 from "./../../../Images/team/team-2.jpg";
import team3 from "./../../../Images/team/team-3.jpg";
import avata01 from "./../../../Images/clients/testimonial-avata-01.jpg";
import avata02 from "./../../../Images/clients/testimonial-avata-02.jpg";
import avata03 from "./../../../Images/clients/testimonial-avata-03.jpg";
import avata04 from "./../../../Images/clients/testimonial-avata-04.jpg";

import office01 from "./../../../Images/bg/cta-bg.jpg";
import logowhite from "./../../../Images/logo.png";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import AllReviews from "../Reviews/AllReviews";
import CycleLoader from "../Shared/Loader/CycleLoader";
const About = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <>
      {loading ? (
        <CycleLoader></CycleLoader>
      ) : (
        <div id="top-header">
          <Header></Header>
          {/* <!--====== Header End ======--> */}
          <section class="page-header">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-xl-8">
                  <div class="title-block">
                    <h1>About Us</h1>
                    <ul class="header-bradcrumb justify-content-center">
                      <li>
                        <Link to="/home">Home</Link>
                      </li>
                      <li class="active" aria-current="page">
                        About
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- About Section Start --> */}
          <section class="about-3 section-padding">
            <div class="container">
              <div class="row align-items-center justify-content-between">
                <div class="col-xl-5 col-lg-6">
                  <div class="about-img">
                    <img src={banner09} alt="" class="img-fluid" />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                  <div class="about-content mt-5 mt-lg-0">
                    <div class="heading mb-50">
                      <span class="subheading">10 years Glory of success</span>
                      <h2 class="font-lg">
                        Some reasons why Start Your Online Learning with Us
                      </h2>
                    </div>

                    <div class="about-features">
                      <div class="feature-item feature-style-left">
                        <div class="feature-icon icon-bg-3 icon-radius">
                          <i class="fa fa-video"></i>
                        </div>
                        <div class="feature-text">
                          <h4>Online Classes</h4>
                          <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Quidem veniam nulla inventore dolores fuga{" "}
                          </p>
                        </div>
                      </div>

                      <div class="feature-item feature-style-left">
                        <div class="feature-icon icon-bg-2 icon-radius">
                          <i class="far fa-file-certificate"></i>
                        </div>
                        <div class="feature-text">
                          <h4>Skilled Instructors</h4>
                          <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Quidem veniam nulla inventore dolores fuga{" "}
                          </p>
                        </div>
                      </div>

                      <div class="feature-item feature-style-left">
                        <div class="feature-icon icon-bg-1 icon-radius">
                          <i class="fad fa-users"></i>
                        </div>
                        <div class="feature-text">
                          <h4>Get Certificate</h4>
                          <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Quidem veniam nulla inventore dolores fuga{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- About Section END -->
<!-- InstructorsSection Start --> */}
          <section class="instructors section-padding-btm">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-xl-6 pe-5">
                  <div class="section-heading">
                    <span class="subheading">Start Today</span>
                    <h2 class="font-lg mb-20">Our Top Instructors</h2>
                    <p class="mb-30">
                      Do you want to be an instructor? Do you want to share your
                      knowledge with everyone? If you have any skill then you
                      can easily share your knowledge online or offline through
                      iLive platform & make money.
                    </p>

                    <ul class="list-item mb-40">
                      <li>
                        <i class="fa fa-check"></i>
                        <h5>
                          Develop your skills Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit.
                        </h5>
                      </li>
                      <li>
                        <i class="fa fa-check"></i>
                        <h5>
                          Share your knowledge Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit.
                        </h5>
                      </li>
                      <li>
                        <i class="fa fa-check"></i>
                        <h5>
                          Earn from globally Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit.
                        </h5>
                      </li>
                    </ul>
                    <a href="#" class="btn btn-main rounded">
                      Start Teaching today
                    </a>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="team-item team-item-2 mt-5">
                        <div class="team-img">
                          <img src={team4} alt="" class="img-fluid" />

                          <ul class="team-socials list-inline">
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="team-content">
                          <div class="team-info">
                            <h4>Harish Ham</h4>
                            <p>SEO Expert</p>
                          </div>

                          <div class="course-meta">
                            <span class="duration">
                              <i class="far fa-user-alt"></i>20 Students
                            </span>
                            <span class="lessons">
                              <i class="far fa-play-circle me-2"></i>2 Course
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="team-item team-item-2">
                        <div class="team-img">
                          <img src={team1} alt="" class="img-fluid" />
                          <ul class="team-socials list-inline">
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="team-content">
                          <div class="team-info">
                            <h4>John doe</h4>
                            <p>CEO, Developer</p>
                          </div>
                          <div class="course-meta">
                            <span class="duration">
                              <i class="far fa-user-alt"></i>20 Students
                            </span>
                            <span class="lessons">
                              <i class="far fa-play-circle me-2"></i>2 Course
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="team-item team-item-2">
                        <div class="team-img">
                          <img src={team2} alt="" class="img-fluid" />

                          <ul class="team-socials list-inline">
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="team-content">
                          <div class="team-info">
                            <h4>Mikel</h4>
                            <p>Web Developer</p>
                          </div>
                          <div class="course-meta">
                            <span class="duration">
                              <i class="far fa-user-alt"></i>20 Students
                            </span>
                            <span class="lessons">
                              <i class="far fa-play-circle me-2"></i>2 Course
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="team-item team-item-2">
                        <div class="team-img">
                          <img src={team3} alt="" class="img-fluid" />

                          <ul class="team-socials list-inline">
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li class="list-inline-item">
                              <a href="#">
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="team-content">
                          <div class="team-info">
                            <h4>Johansam</h4>
                            <p>Marketer</p>
                          </div>

                          <div class="course-meta">
                            <span class="duration">
                              <i class="far fa-user-alt"></i>20 Students
                            </span>
                            <span class="lessons">
                              <i class="far fa-play-circle me-2"></i>2 Course
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--Instructors Section End -->
<!-- Counter Section start --> */}
          <section class="counter-section4">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 counter-inner">
                  <div class="row">
                    <div class="col-lg-3 col-md-6">
                      <div class="counter-item mb-5 mb-lg-0">
                        <div class="count">
                          <span class="counter h2">2000</span>
                          <span>+</span>
                        </div>
                        <p>Students</p>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                      <div class="counter-item mb-5 mb-lg-0">
                        <div class="count">
                          <span class="counter h2">1200</span>
                        </div>
                        <p>Online Courses</p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="counter-item mb-5 mb-lg-0">
                        <div class="count">
                          <span class="counter h2">2256</span>
                        </div>
                        <p>Finished Seasons</p>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                      <div class="counter-item">
                        <div class="count">
                          <span class="counter h2">100</span>
                          <span>%</span>
                        </div>
                        <p>Satisfaction</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- COunter Section End -->



<!-- Testimonial section start --> */}
          <AllReviews></AllReviews>
          {/* <!-- Testimonial section End -->
<!-- Work Process Section Start --> */}
          <section class="work-process section-padding">
            <div class="container">
              <div class="row mb-70 justify-content-between">
                <div class="col-xl-5 col-lg-6">
                  <div class="section-heading mb-4 mb-xl-0">
                    <span class="subheading">How to Start</span>
                    <h2 class="font-lg">4 steps start your journey with us</h2>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                  <p>
                    Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                    venenatis vestibulum. Etiam porta sem malesuada magna mollis
                    euismod. Nullam id dolor id nibh ultricies vehicula ut id
                    elit. Nullam quis risus eget urna mollis.
                  </p>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-xl-7 pe-xl-5 col-lg-12">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6">
                      <div class="step-item ">
                        <div class="step-number bg-1">01</div>
                        <div class="step-text">
                          <h5>Signup with all info</h5>
                          <p>
                            Lorem ipsum dolor seat ameat dui too consecteture
                            elite adipaising.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6">
                      <div class="step-item">
                        <div class="step-number bg-2">02</div>
                        <div class="step-text">
                          <h5>Take your Admission</h5>
                          <p>
                            Lorem ipsum dolor seat ameat dui too consecteture
                            elite adipaising.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6">
                      <div class="step-item ">
                        <div class="step-number bg-3">03</div>
                        <div class="step-text">
                          <h5>Learn from online </h5>
                          <p>
                            Lorem ipsum dolor seat ameat dui too consecteture
                            elite adipaising.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6">
                      <div class="step-item ">
                        <div class="step-number bg-1">04</div>
                        <div class="step-text">
                          <h5>Get certificate</h5>
                          <p>
                            Lorem ipsum dolor seat ameat dui too consecteture
                            elite adipaising.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-5 col-lg-12">
                  <div class="video-section mt-5 mt-xl-0">
                    <div class="video-content">
                      <img src={office01} alt="" class="img-fluid" />
                      <a href="#" class="video-icon video-popup">
                        <i class="fa fa-play"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Work Process Section End --> */}

          {/* <!-- Footer section start --> */}
          <Footer padding={false}></Footer>
        </div>
      )}
    </>
  );
};

export default About;
