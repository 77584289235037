import React from "react";
import PopularCourse from "./PopularCourse";

const CourseSidebar = () => {
  return (
    <div class="col-xl-4 col-lg-5">
      <div class="course-sidebar course-sidebar-5 mt-5 mt-xl-0">
        <div class="course-widget course-details-info">
          <div class="price-header">
            <h2 class="course-price">
              $120.00 <span>$150</span>
            </h2>
            <span class="course-price-badge onsale">39% off</span>
          </div>
          <ul class="course-sidebar-list">
            <li>
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  <i class="far fa-sliders-h"></i>Level
                </span>
                Beginnner
              </div>
            </li>

            <li>
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  <i class="fas fa-play-circle"></i>Lectures
                </span>
                2
              </div>
            </li>
            <li>
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  <i class="far fa-user"></i>Students
                </span>
                20
              </div>
            </li>
            <li>
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  <i class="far fa-clock"></i>Duration
                </span>
                6 hours
              </div>
            </li>
            <li>
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  <i class="far fa-globe"></i>Language
                </span>
                English
              </div>
            </li>

            <li>
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  <i class="far fa-calendar"></i>Updated{" "}
                </span>
                October 15, 2022
              </div>
            </li>
          </ul>

          <div class="buy-btn">
            <button class="button button-enroll-course btn btn-main-2 rounded">
              <i class="far fa-shopping-cart me-2"></i> Enroll Course
            </button>
          </div>

          <div class="course-meterial">
            <h4 class="mb-3">Material Includes</h4>
            <ul class="course-meterial-list">
              <li>
                <i class="fal fa-long-arrow-right"></i>Videos
              </li>
              <li>
                <i class="fal fa-long-arrow-right"></i>Files For Development
              </li>
              <li>
                <i class="fal fa-long-arrow-right"></i>Documentation Files
              </li>
            </ul>
          </div>
        </div>
        <PopularCourse></PopularCourse>
      </div>
    </div>
  );
};

export default CourseSidebar;
