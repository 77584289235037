import React from "react";

const CoursesUpperBanner = ({ pageTitle }) => {
  return (
    <section class="page-header">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-xl-8">
            <div class="title-block">
              <h1>{pageTitle}</h1>
              <ul class="header-bradcrumb justify-content-center">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li class="active" aria-current="page">
                  Courses
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoursesUpperBanner;
