import React from "react";

const CourseReview = () => {
  return (
    <div
      class="tab-pane fade"
      id="nav-feedback"
      role="tabpanel"
      aria-labelledby="nav-feedback-tab"
    >
      <div id="course-reviews">
        <ul class="course-reviews-list">
          <li>
            <div class="course-review">
              <div class="course-single-review">
                <div class="user-image">
                  <img
                    src="assets/images/blog/author.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <div class="user-content user-review-content">
                  <div class="review-header mb-10">
                    <h4 class="user-name">Tutori</h4>
                    <p class="review-title">Cover all topicc </p>
                    <div class="rating review-stars-rated">
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-star-half"></i>
                      </a>
                    </div>
                  </div>
                  <div class="review-text">
                    <div class="review-content">
                      The course identify things we want to change and then
                      figure out the things that need to be done to create the
                      desired outcome. The course helped me in clearly define
                      problems and generate a wider variety of quality
                      solutions. Support more structures analysis of options
                      leading to better decisions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="course-review">
              <div class="course-single-review">
                <div class="user-image">
                  <img
                    src="assets/images/blog/author.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <div class="user-content user-review-content">
                  <div class="review-header mb-10">
                    <h4 class="user-name">Tutori</h4>
                    <p class="review-title">Cover all topicc </p>
                    <div class="rating review-stars-rated">
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-star"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-star-half"></i>
                      </a>
                    </div>
                  </div>
                  <div class="review-text">
                    <div class="review-content">
                      The course identify things we want to change and then
                      figure out the things that need to be done to create the
                      desired outcome. The course helped me in clearly define
                      problems and generate a wider variety of quality
                      solutions. Support more structures analysis of options
                      leading to better decisions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseReview;
