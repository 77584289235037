import React from "react";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
//Images
import courseimg01 from "./../../../Images/course/img_01.jpg";
import courseimg02 from "./../../../Images/course/img_02.jpg";
import courseimg03 from "./../../../Images/course/img_03.jpg";
import courseimg04 from "./../../../Images/course/img_04.jpg";
import courseimg05 from "./../../../Images/course/img_05.jpg";
import courseimg06 from "./../../../Images/course/img_06.jpg";
import course2 from "./../../../Images/course/course-2.jpg";
import Courses from "./Courses";
import CoursesUpperBanner from "./CoursesUpperBanner";
import CoursesSearchBar from "./CoursesSearchBar";
const Courses1 = () => {
  return (
    <div>
      <Header></Header>
      <CoursesUpperBanner pageTitle="Course Layout 1"></CoursesUpperBanner>

      <section class="section-padding page">
        <CoursesSearchBar></CoursesSearchBar>

        <div class="container">
          <div class="row justify-content-center">
            <Courses
              img1={courseimg01}
              img2={course2}
              instructor="Josephin"
              price="$450"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Adobe Lightroom: Photo Editing Masterclass"
            ></Courses>
            <Courses
              img1={courseimg02}
              img2={course2}
              instructor="Josephin"
              price="$22
              0"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Writing With Flair: Become a Writer"
            ></Courses>
            <Courses
              img1={courseimg03}
              img2={course2}
              instructor="Josephin"
              price="$330"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Cinematography Course: Shoot Better Video "
            ></Courses>
            <Courses
              img1={courseimg06}
              img2={course2}
              instructor="Josephin"
              price="$300"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Data Competitive Strategy"
            ></Courses>
            <Courses
              img1={courseimg02}
              img2={course2}
              instructor="Josephin"
              price="$220"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Writing With Flair: Become a Writer"
            ></Courses>
            <Courses
              img1={courseimg04}
              img2={course2}
              instructor="Josephin"
              price="$300"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Cinematography Course: Shoot Better Video"
            ></Courses>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </div>
  );
};

export default Courses1;
