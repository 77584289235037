import React from "react";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import CoursesLayout3 from "./CoursesLayout3";
import CoursesSearchBar from "./CoursesSearchBar";
import CoursesUpperBanner from "./CoursesUpperBanner";
//Images
import courseimg01 from "./../../../Images/course/img_01.jpg";
import courseimg02 from "./../../../Images/course/img_02.jpg";
import courseimg03 from "./../../../Images/course/img_03.jpg";
import courseimg04 from "./../../../Images/course/img_04.jpg";
import courseimg05 from "./../../../Images/course/img_05.jpg";
import courseimg06 from "./../../../Images/course/img_06.jpg";
import course2 from "./../../../Images/course/course-2.jpg";
const Courses3 = () => {
  return (
    <div id="top-header">
      <Header></Header>
      <CoursesUpperBanner pageTitle="Course Layout 3"></CoursesUpperBanner>
      <section className="section-padding page">
        <CoursesSearchBar></CoursesSearchBar>
        <div className="container">
          <div className="row justify-content-center">
            <CoursesLayout3
              img1={courseimg06}
              img2={course2}
              instructor="Josephin"
              price="$450"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Data Competitive Strategy law & Organization "
              studentNumber="51"
            ></CoursesLayout3>
            <CoursesLayout3
              img1={courseimg06}
              img2={course2}
              instructor="Josephin"
              price="$450"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Data Competitive Strategy law & Organization "
              studentNumber="51"
            ></CoursesLayout3>
            <CoursesLayout3
              img1={courseimg06}
              img2={course2}
              instructor="Josephin"
              price="$450"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Data Competitive Strategy law & Organization "
              studentNumber="51"
            ></CoursesLayout3>
            <CoursesLayout3
              img1={courseimg06}
              img2={course2}
              instructor="Josephin"
              price="$450"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Data Competitive Strategy law & Organization "
              studentNumber="51"
            ></CoursesLayout3>
            <CoursesLayout3
              img1={courseimg06}
              img2={course2}
              instructor="Josephin"
              price="$450"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Data Competitive Strategy law & Organization "
              studentNumber="51"
            ></CoursesLayout3>
            <CoursesLayout3
              img1={courseimg06}
              img2={course2}
              instructor="Josephin"
              price="$450"
              duration="6 hours"
              lectureNumber="26 Lectures"
              title="Data Competitive Strategy law & Organization "
              studentNumber="51"
            ></CoursesLayout3>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Courses3;
