import React from "react";
import Header from "../Shared/Header/Header";
import CoursesSearchBar from "./CoursesSearchBar";
import CoursesUpperBanner from "./CoursesUpperBanner";
//Images
import courseimg01 from "./../../../Images/course/img_01.jpg";
import courseimg02 from "./../../../Images/course/img_02.jpg";
import courseimg03 from "./../../../Images/course/img_03.jpg";
import courseimg04 from "./../../../Images/course/img_04.jpg";
import courseimg05 from "./../../../Images/course/img_05.jpg";
import courseimg06 from "./../../../Images/course/img_06.jpg";
import course2 from "./../../../Images/course/course-2.jpg";
import CourselistLayout from "./CourselistLayout";
const CoursesList = () => {
  return (
    <div>
      <Header></Header>
      <CoursesUpperBanner pageTitle="Course List Layout"></CoursesUpperBanner>
      <section className="section-padding page">
        <CoursesSearchBar></CoursesSearchBar>
        <div className="container">
          <div className="row">
            <CourselistLayout
              image={courseimg01}
              title="Data Competitive Strategy law & Organization"
              bgImage={course2}
              price="300"
              author="Josephine"
              category="Design"
              students="51"
            ></CourselistLayout>
            <CourselistLayout
              image={courseimg02}
              title="Data Competitive Strategy law & Organization"
              bgImage={course2}
              price="300"
              author="Josephine"
              category="Design"
              students="51"
            ></CourselistLayout>
            <CourselistLayout
              image={courseimg03}
              title="Data Competitive Strategy law & Organization"
              bgImage={course2}
              price="300"
              author="Josephine"
              category="Design"
              students="51"
            ></CourselistLayout>
            <CourselistLayout
              image={courseimg04}
              title="Data Competitive Strategy law & Organization"
              bgImage={course2}
              price="300"
              author="Josephine"
              category="Design"
              students="51"
            ></CourselistLayout>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CoursesList;
