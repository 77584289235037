import React from "react";

const CourseInstructor = () => {
  return (
    <div
      class="tab-pane fade"
      id="nav-instructor"
      role="tabpanel"
      aria-labelledby="nav-instructor-tab"
    >
      {/* <!-- Course instructor start --> */}
      <div class="courses-instructor">
        <div class="single-instructor-box">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-4">
              <div class="instructor-image">
                <img
                  src="assets/images/blog/author.jpg"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="col-lg-8 col-md-8">
              <div class="instructor-content">
                <h4>
                  <a href="#">Tutori</a>
                </h4>
                <span class="sub-title">Web Developer</span>

                <p>
                  Jone Smit is a celebrated photographer, author, and writer who
                  brings passion to everything he does.
                </p>

                <div class="intructor-social-links">
                  <span class="me-2">Follow Me: </span>
                  <a href="#">
                    {" "}
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    {" "}
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    {" "}
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                  <a href="#">
                    {" "}
                    <i class="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Conurse  instructor end --> */}
    </div>
  );
};

export default CourseInstructor;
