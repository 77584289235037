import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  // apiKey: `${process.env.REACT_APP_apiKey}`,
  // authDomain: `${process.env.REACT_APP_authDomain}`,
  // projectId: `${process.env.REACT_APP_projectId}`,
  // storageBucket: `${process.env.REACT_APP_storageBucket}`,
  // messagingSenderId: `${process.env.REACT_APP_messagingSenderId}`,
  // appId: `${process.env.REACT_APP_appId}`,
  //   measurementId: process.env.REACT_APP_measurementId
  apiKey: "AIzaSyCjb71y657h7yojeozIIeM-01cEWlYMSdY",
  authDomain: "binaryclassroom-812c0.firebaseapp.com",
  projectId: "binaryclassroom-812c0",
  storageBucket: "binaryclassroom-812c0.appspot.com",
  messagingSenderId: "899934171077",
  appId: "1:899934171077:web:f5a41e4b033d0adb0665ac",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
