import React from "react";
import courseImage01 from "./../../../../../Images/course/img_01.jpg";
import courseImage02 from "./../../../../../Images/course/img_02.jpg";
import courseImage03 from "./../../../../../Images/course/img_03.jpg";
//later it will loaded from the database
const PopularCourse = () => {
  return (
    <div class="course-latest">
      <h4 class="mb-4">Popular Courses</h4>
      <ul class="recent-posts course-popular">
        <li>
          <div class="widget-post-thumb">
            <a href="#">
              <img src={courseImage01} alt="" class="img-fluid" />
            </a>
          </div>
          <div class="widget-post-body">
            <h6>
              {" "}
              <a href="#">Wordpress Theme Development</a>
            </h6>
            <h5>$120</h5>
          </div>
        </li>
        <li>
          <div class="widget-post-thumb">
            <a href="#">
              <img src={courseImage02} alt="" class="img-fluid" />
            </a>
          </div>
          <div class="widget-post-body">
            <h6>
              {" "}
              <a href="#">mastering Photoshop for beginners</a>
            </h6>
            <h5>$100</h5>
          </div>
        </li>
        <li>
          <div class="widget-post-thumb">
            <a href="#">
              <img src={courseImage03} alt="" class="img-fluid" />
            </a>
          </div>
          <div class="widget-post-body">
            <h6>
              {" "}
              <a href="#">Photography mastering adobe tool</a>
            </h6>
            <h5>$100</h5>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default PopularCourse;
