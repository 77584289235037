import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
//Images
import logopik from "./../../../Images/logo.png";

import team1 from "./../../../Images/team/team-1.jpg";
import team2 from "./../../../Images/team/team-2.jpg";
import team3 from "./../../../Images/team/team-3.jpg";
import team4 from "./../../../Images/team/team-4.jpg";
export default function Instructors() {
  return (
    <div id="top-header">
      <Header></Header>
      {/* <!--====== Header End ======--> */}
      <section class="page-header">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-8">
              <div class="title-block">
                <h1>Instructors</h1>
                <ul class="header-bradcrumb justify-content-center">
                  <li>
                    <Link to="/home">Home </Link>
                  </li>
                  <li class="active" aria-current="page">
                    Instructors
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-instructors section-padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5">
                <div class="team-img">
                  <img src={team4} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>SEO Expert</p>
                  </div>

                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5">
                <div class="team-img">
                  <img src={team1} alt="" class="img-fluid" />
                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>CEO, Developer</p>
                  </div>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5">
                <div class="team-img">
                  <img src={team2} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>Web eveloper</p>
                  </div>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5">
                <div class="team-img">
                  <img src={team3} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>Marketer</p>
                  </div>

                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5">
                <div class="team-img">
                  <img src={team1} alt="" class="img-fluid" />
                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>CEO, Developer</p>
                  </div>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5">
                <div class="team-img">
                  <img src={team4} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>SEO Expert</p>
                  </div>

                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5">
                <div class="team-img">
                  <img src={team3} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>Marketer</p>
                  </div>

                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5">
                <div class="team-img">
                  <img src={team2} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>Web eveloper</p>
                  </div>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Footer section start --> */}
      <Footer padding={false}></Footer>
      {/* <!-- Footer section End --> */}
    </div>
  );
}
