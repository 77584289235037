const GetFirebaseError = (error) => {
  const errorMessage = error.message;
  //  console.log(errorMessage);

  let newmessage = errorMessage.split(" ");
  newmessage = newmessage[2].split("/");
  newmessage = newmessage[1].split(")");
  newmessage = newmessage[0].split("-");
  //  console.log(newmessage);
  let msg = newmessage.join(" ");
  return msg;
};
export default GetFirebaseError;
