import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import spinner from "./../../../Images/Spinner.gif";
import swal from "sweetalert";
//Images
import logopik from "./../../../Images/logo.png";
import auth from "../../../firebase.config";
import GetFirebaseError from "../../Hooks/GetFirebaseError";
import login from "./../../../Images/Login.svg";
import CycleLoader from "../Shared/Loader/CycleLoader";

export default function Login() {
  const [loadingl, setLoading] = useState(false);
  const naviagte = useNavigate();
  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);
  if (error) {
    let msg = GetFirebaseError(error);
    swal("", `${msg}`, "error");
  }

  if (user) {
    swal("", "Log In successful", "success").then((val) => naviagte("/home"));
  }
  const onSubmit = (data) => {
    signInWithEmailAndPassword(data.email, data.password);
  };
  return (
    <>
      {loadingl ? (
        <CycleLoader></CycleLoader>
      ) : (
        <div className="my-10">
          <div id="top-header ">
            <Header></Header>
            {/* <!--====== Header End ======--> */}
            <div className="flex flex-col lg:flex-row w-[100%] items-center lg:justify-evenly">
              <img
                className="lg:max-w-[35%] md:w-[75%] w-[90%] object-fill"
                src={login}
                alt=""
              />
              <section class="page-wrapper md:w-[75%] w-[90%] lg:w-[35%] woocommerce single">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="">
                      <div class="woocommerce-notices-wrapper"></div>
                      <div class="login-form">
                        <div class="form-header">
                          <h2 class="font-weight-bold mb-3">Login</h2>

                          <p class="woocommerce-register">
                            Don't have an account yet?{" "}
                            <Link
                              to="/register"
                              clLinkss="text-decoration-underline"
                            >
                              Sign Up for Free
                            </Link>
                          </p>
                        </div>
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          class="woocommerce-form woocommerce-form-login login"
                          method="post"
                        >
                          <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                            <label for="username">
                              email address&nbsp;<span class="required">*</span>
                            </label>
                            <input
                              type="email"
                              className="w-[100%] "
                              {...register("email", { required: true })}
                              placeholder=" Email"
                            />
                          </p>
                          <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                            <label for="password">
                              Password&nbsp;<span class="required">*</span>
                            </label>
                            <input
                              class="woocommerce-Input woocommerce-Input--text input-text form-control"
                              type="password"
                              name="password"
                              id="password"
                              {...register("password", { required: true })}
                              autocomplete="current-password"
                              placeholder="Password"
                            />
                          </p>

                          <div class="d-flex align-items-center justify-content-between py-2">
                            <p class="form-row">
                              <label class="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__rememberme">
                                <input
                                  class="woocommerce-form__input  accent-slate-600  
                        woocommerce-form__input-checkbox"
                                  name="rememberme"
                                  type="checkbox"
                                  id="rememberme"
                                  value="forever"
                                />{" "}
                                <span>Remember me</span>
                              </label>
                            </p>

                            <p class="woocommerce-LostPassword lost_password">
                              <a href="#">Forgot password?</a>
                            </p>
                          </div>

                          <p class="form-row flex h-[50px]">
                            <input
                              className="w-[100%] btn-submit hover:opacity-70 text-white uppercase text-xl font-semibold bg-[#015ABD]"
                              value="Log In"
                              type="submit"
                            />
                            {loading ? <img src={spinner} alt="" /> : <></>}
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* <!--shop category end-->

<!-- Footer section start --> */}

            <Footer padding={false}></Footer>
            {/* <!-- Footer section End --> */}
          </div>
        </div>
      )}
    </>
  );
}
