import React from "react";

const CourselistLayout = ({
  image,
  bgImage,
  author,
  title,
  price,
  category,
  students,
}) => {
  return (
    <div class="course-item col-lg-6 col-md-6">
      <div class="single-course style-2 bg-shade border-0">
        <div class="row g-0 align-items-center">
          <div class="col-xl-5">
            <div
              class="course-thumb"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <span class="category">{category}</span>
            </div>
          </div>
          <div class="col-xl-7">
            <div class="course-content py-4 pt-xl-0">
              <div class="course-price">${price}</div>
              <h3 class="course-title">
                {" "}
                <a href="#">{title}</a>{" "}
              </h3>
              <div class="course-meta d-flex align-items-center">
                <div class="author">
                  <img src={image} alt="" class="img-fluid" />
                  <a href="#">{author}</a>
                </div>
                <span class="students">
                  <i class="far fa-user-alt"></i>
                  {students} Students
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourselistLayout;
