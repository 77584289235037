import React, { useState } from "react";

export const blogContext = React.createContext();
export const BlogContextProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);
  //   const [problems, setProblems] = useState([]);
  //   const [medicines, setMedicines] = useState([]);
  //   const [medications, setMedications] = useState([]);
  //   const [lastInfo, setLastInfo] = useState([]);
  //   const [nextApp, setNextApp] = useState("0 days");

  return (
    <blogContext.Provider
      value={{
        blogs,
        setBlogs,
      }}
    >
      {children}
    </blogContext.Provider>
  );
};
