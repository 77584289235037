import React from "react";
import { Link } from "react-router-dom";
import CourseInstructor from "./CourseInstructor";
import CourseReview from "./CourseReview";
import Curriculam from "./Curriculam";
import Overview from "./Overview";

const CourseNavbar = () => {
  return (
    <div class="course-single-tabs learn-press-nav-tabs">
      {/* <nav class="course-single-tabs learn-press-nav-tabs">
        <div class="nav nav-tabs course-nav" id="nav-tab" role="tablist">
          <a
            class="nav-item nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            href="#nav-home"
            role="tab"
            aria-controls="nav-home-tab"
            aria-selected="true"
          >
            Overview
          </a>
          <a
            class="nav-item nav-link"
            id="nav-topics-tab"
            data-bs-toggle="tab"
            href="#nav-topics"
            role="tab"
            aria-controls="nav-topics-tab"
            aria-selected="false"
          >
            Curriculam
          </a>
          <a
            class="nav-item nav-link"
            id="nav-instructor-tab"
            data-bs-toggle="tab"
            href="#nav-instructor"
            role="tab"
            aria-controls="nav-instructor-tab"
            aria-selected="false"
          >
            Instructor
          </a>
          <a
            class="nav-item nav-link"
            id="nav-feedback-tab"
            data-bs-toggle="tab"
            href="#nav-feedback"
            role="tab"
            aria-controls="nav-feedback-tab"
            aria-selected="false"
          >
            Reviews
          </a>
        </div>
      </nav>
      <div class="tab-content tutori-course-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <Overview></Overview>
        </div>
        <div
          class="tab-pane fade"
          id="nav-topics"
          role="tabpanel"
          aria-labelledby="nav-topics-tab"
        >
          <Curriculam></Curriculam>
        </div>
        <div
          class="tab-pane fade"
          id="nav-instructor"
          role="tabpanel"
          aria-labelledby="nav-instructor-tab"
        >
          <CourseInstructor></CourseInstructor>
        </div>
        <div
          class="tab-pane fade"
          id="nav-feedback"
          role="tabpanel"
          aria-labelledby="nav-feedback-tab"
        >
          <CourseReview></CourseReview>
        </div> */}
      <Link to="courseOverview">Overview</Link>
      <Link to="/courseCurriculum">Curriculum</Link>
      <Link to="/courseInstructor">Instructor</Link>
      <Link to="/courseReview">Review</Link>
    </div>
  );
};

export default CourseNavbar;
