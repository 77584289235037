import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import edjsParser from "editorjs-parser";
import "react-quill/dist/quill.snow.css";
import EditorJS from "@editorjs/editorjs";
import Header1 from "@editorjs/header";
import SimpleImage from "@editorjs/image";

import Checklist from "@editorjs/checklist";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import Warning from "@editorjs/warning";
import Marker from "@editorjs/marker";

import CodeTool from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import LinkTool from "@editorjs/link";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Select from "react-select";

import makeAnimated from "react-select/animated";
import { MdOutlineSubtitles } from "react-icons/md";
import { set, useForm } from "react-hook-form";
import { BiTennisBall } from "react-icons/bi";
import {
  AiOutlineFileImage,
  AiOutlineWarning,
  AiFillCheckCircle,
} from "react-icons/ai";

import { ImCross } from "react-icons/im";
import Header from "./../../Pages/Shared//Header/Header";
import Footer from "../Shared/Footer/Footer";

import { blogContext } from "../../Context/blogContext";
const animatedComponents = makeAnimated();
const Editor2 = () => {
  const [blogData, setBlogData] = useState({});
  const { blogs, setBlogs } = useContext(blogContext);
  // const [blocks, setBlocks] = useState([]);
  // const [isSubtitleClicked, setSubtitleClicked] = useState(false);
  // const [isUploaded, setUploaded] = useState("nothing");
  const [userChoice, setUserChoice] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [cover, setCover] = useState("");
  // const parser = new edjsParser();
  // // configuration of editor
  // let editor;
  // React.useEffect(() => {
  //   try {
  //     editor = new EditorJS({
  //       /**

  //        */

  //       holder: "editorjs",
  //       tools: {
  //         header: {
  //           class: Header1,
  //           inlineToolbar: true,
  //           config: {
  //             placeholder: "Enter a header",
  //             levels: [2, 3, 4],
  //             defaultLevel: 3,
  //           },
  //         },

  //         list: {
  //           class: List,
  //           inlineToolbar: ["link", "bold"],
  //         },

  //         quote: {
  //           class: Quote,
  //           config: {
  //             quotePlaceholder: "Enter a quote",
  //             captionPlaceholder: "Quote's author",
  //           },
  //         },
  //         warning: Warning,
  //         marker: Marker,
  //         code: CodeTool,
  //         delimiter: Delimiter,
  //         inlineCode: InlineCode,

  //         embed: {
  //           class: Embed,
  //           inlineToolbar: false,
  //           config: {
  //             services: {
  //               youtube: true,
  //               coub: true,
  //             },
  //           },
  //         },
  //         table: Table,
  //       },
  //     });
  //   } catch (er) {
  //     console.log(er);
  //   }
  // }, [blocks]);

  // let HTML;

  // const options = [
  //   { value: "Java", label: "Java" },
  //   { value: "Javascript", label: "Javascript" },
  //   { value: "Python", label: "Python" },
  //   { value: "Rustc", label: "Rustc" },
  //   { value: "Web Development", label: "Web Development" },
  //   { value: "Competitive Programming", label: "Competitive Programming" },
  //   { value: "React", label: "React" },
  //   { value: "Machine Learning", label: "Machine Learning" },
  //   { value: "Artificial Intelligence", label: "Artificial Intelligence" },
  //   { value: "Cyber Security", label: "Cyber Security" },
  //   { value: "Cpp", label: "Cpp" },
  //   { value: "Data Structure", label: "Data Structure" },
  //   { value: "Algorithm", label: "Algorithm" },
  //   { value: "Neural Science", label: "Neural Science" },
  //   { value: "Graphs", label: "Graphs" },
  // ];

  // const CustomStyle = {
  //   menuList: (styles) => ({
  //     ...styles,
  //     background: "white",
  //     color: "black",
  //   }),
  //   option: (styles, { isFocused, isSelected }) => ({
  //     ...styles,
  //     background: isFocused ? "#000" : isSelected ? "#fff" : undefined,
  //     color: isFocused ? "#fff" : isSelected ? "#000" : undefined,
  //     zIndex: 1,
  //   }),
  //   menu: (base) => ({
  //     ...base,
  //     zIndex: 100,
  //   }),
  // };
  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm();
  // const onSubmit = (data) => {
  //   console.log(data);
  // };

  // async function uploadImageCallBack() {
  //   const file = document.getElementById("input-img");
  //   console.log(file.files);
  //   const uploadFile = file.files[0];
  //   var form = new FormData();
  //   form.append("image", uploadFile);
  //   try {
  //     var url =
  //       "https://api.imgbb.com/1/upload?key=6458ef300036886b2480e9e05257aae4";
  //     const config = {
  //       method: "POST",

  //       body: form,
  //     };
  //     const response = await fetch(url, config);
  //     //return response;
  //     const json = await response.json();
  //     setUploaded("true");
  //     console.log(json.data.display_url);
  //     setCover(json?.data?.display_url);
  //   } catch (err) {
  //     //console.log("jell");
  //     setUploaded("false");
  //     console.log(err.message);
  //   }

  // }

  // const editorData = () => {
  //   editor
  //     .save()
  //     .then((outputData) => {
  //       HTML = parser.parse(outputData);
  //       console.log("Article data: ", outputData.blocks);
  //       setBlocks(outputData?.blocks);
  //       console.log("dwasdad", HTML);
  //       // const res1 = convertDataToHtml(outputData.blocks);
  //       // setRes(res1);
  //       // console.log(res);
  //     })
  //     .catch((error) => {
  //       console.log("Saving failed: ", error);
  //     });
  // };
  // useEffect(() => {
  //   // console.log("title", title);
  //   blogData["title"] = title;
  //   //  console.log("all blocks", blocks);
  //   //  console.log("subtitle", subtitle);
  //   // console.log("imgLink ", cover);
  //   let updateData = {};
  //   updateData = { title, subtitle, cover };
  //   setBlogData(updateData);
  // }, [title, subtitle, cover, blocks]);
  // useEffect(() => {
  //   console.log(blogData);
  // }, [blogData]);
  // ALL states
  const [blocks, setBlocks] = useState([]);
  const [isSubtitleClicked, setSubtitleClicked] = useState(false);
  const [isUploaded, setUploaded] = useState("nothing");
  const parser = new edjsParser();
  // configuration of editor
  //let editor;
  const [editor, setEditor] = useState("");
  useEffect(() => {
    editor === "" &&
      setEditor(
        () =>
          new EditorJS({
            holder: "editorjs",
            logLevel: "VERBOSE",
            placeholder: "Any new inspirations today?",
            tools: {
              header: {
                class: Header1,
                inlineToolbar: true,
                config: {
                  placeholder: "Enter a header",
                  levels: [2, 3, 4],
                  defaultLevel: 3,
                },
              },

              list: {
                class: List,
                inlineToolbar: ["link", "bold"],
              },

              quote: {
                class: Quote,
                config: {
                  quotePlaceholder: "Enter a quote",
                  captionPlaceholder: "Quote's author",
                },
              },
              warning: Warning,
              marker: Marker,
              code: CodeTool,
              delimiter: Delimiter,
              inlineCode: InlineCode,

              embed: {
                class: Embed,
                inlineToolbar: false,
                config: {
                  services: {
                    youtube: true,
                    coub: true,
                  },
                },
              },
              table: Table,
            },
          })
      );
  });
  // React.useEffect(() => {
  //   try {
  //     editor = new EditorJS({
  //       /**
  //        * Id of Element that should contain the Editor
  //        */

  //       holder: "editorjs",
  //       tools: {
  //         header: {
  //           class: Header1,
  //           inlineToolbar: true,
  //           config: {
  //             placeholder: "Enter a header",
  //             levels: [2, 3, 4],
  //             defaultLevel: 3,
  //           },
  //         },

  //         list: {
  //           class: List,
  //           inlineToolbar: ["link", "bold"],
  //         },

  //         quote: {
  //           class: Quote,
  //           config: {
  //             quotePlaceholder: "Enter a quote",
  //             captionPlaceholder: "Quote's author",
  //           },
  //         },
  //         warning: Warning,
  //         marker: Marker,
  //         code: CodeTool,
  //         delimiter: Delimiter,
  //         inlineCode: InlineCode,

  //         embed: {
  //           class: Embed,
  //           inlineToolbar: false,
  //           config: {
  //             services: {
  //               youtube: true,
  //               coub: true,
  //             },
  //           },
  //         },
  //         table: Table,
  //       },
  //     });
  //   } catch (er) {
  //     console.log(er);
  //   }
  // }, []);
  // const editor = new EditorJS("editorjs", {
  //   holderId: "editorjs",
  //   tools: {
  //     header: {
  //       class: Header1,
  //       shortcut: "CMD+SHIFT+H",
  //
  //     },

  //   },
  // });
  let HTML;
  // configuration of selectoptions
  const options = [
    { value: "Java", label: "Java" },
    { value: "Javascript", label: "Javascript" },
    { value: "Python", label: "Python" },
    { value: "Rustc", label: "Rustc" },
    { value: "Web Development", label: "Web Development" },
    { value: "Competitive Programming", label: "Competitive Programming" },
    { value: "React", label: "React" },
    { value: "Machine Learning", label: "Machine Learning" },
    { value: "Artificial Intelligence", label: "Artificial Intelligence" },
    { value: "Cyber Security", label: "Cyber Security" },
    { value: "Cpp", label: "Cpp" },
    { value: "Data Structure", label: "Data Structure" },
    { value: "Algorithm", label: "Algorithm" },
    { value: "Neural Science", label: "Neural Science" },
    { value: "Graphs", label: "Graphs" },
  ];

  const CustomStyle = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
      color: "black",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#000" : isSelected ? "#fff" : undefined,
      color: isFocused ? "#fff" : isSelected ? "#000" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  async function uploadImageCallBack() {
    const file = document.getElementById("input-img");
    console.log(file.files);
    const uploadFile = file.files[0];
    var form = new FormData();
    form.append("image", uploadFile);
    try {
      var url =
        "https://api.imgbb.com/1/upload?key=6458ef300036886b2480e9e05257aae4";
      const config = {
        method: "POST",

        body: form,
      };
      const response = await fetch(url, config);
      //return response;
      const json = await response.json();
      setUploaded("true");
      setCover(json?.data?.display_url);
      console.log(json.data.display_url);
    } catch (err) {
      console.log("jell");
      setUploaded("false");
      console.log(err.message);
    }

    //  return json.data.display_url;
    // return new Promise((resolve, reject) => {
    //   resolve({ data: { link: `${json.data.display_url}` } });
    // });
  }
  const [res, setRes] = useState(<></>);
  const editorData = () => {
    editor
      .save()
      .then((outputData) => {
        HTML = parser.parse(outputData);
        console.log("Article data: ", outputData.blocks);
        setBlocks(outputData?.blocks);
        console.log("dwasdad", HTML);
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };
  useEffect(() => {
    // console.log("title", title);
    // blogData["title"] = title;
    //  console.log("all blocks", blocks);
    //  console.log("subtitle", subtitle);
    // console.log("imgLink ", cover);
    let updateData = {};
    updateData = { blocks, subtitle, title, cover, userChoice };
    console.log(userChoice);
    // updateData = { blocks };
    //setBlogData(updateData);
    setBlogs([...blogs, updateData]);
    console.log(updateData);
    console.log(blogs.length);
  }, [blocks]);
  const d = "qweqeqwieujkoqwe<i>jwqoeuqwoiuei</i>wq";
  return (
    <>
      <Header></Header>
      <div className="my-40 font-mono">
        <h1 className="text-center font-semibold my-3">Create Your Own Blog</h1>
        <div className="w-[80%] mx-auto flex justify-end">
          <button
            onClick={editorData}
            className="mx-2 px-5 py-1 rounded-3xl font-semibold duration-500 hover:bg-[#F14D5D] bg-[#015ABD] text-[white]"
          >
            Publish
          </button>
        </div>

        <input
          onChange={(e) => setTitle(e.target.value)}
          className=" block mx-auto font-mono border-[#b1a8a8] w-[80%] rounded-md py-2 text-3xl placeholder:text-4xl my-3 px-3"
          placeholder="Title"
          type="text"
          required
        />
        <p
          className=" flex items-center gap-7 justify-start  border-black  py-1 w-[80%] mx-auto
"
        >
          <p
            onClick={() => setSubtitleClicked(!isSubtitleClicked)}
            className="flex  items-center gap-2 cursor-pointer"
          >
            <MdOutlineSubtitles className=" text-black font-semibold"></MdOutlineSubtitles>{" "}
            <span className="cursor-pointer text-black font-semibold">
              Add Subtitle
            </span>
          </p>
          <p className="flex  items-center text-black font-semibold gap-1 cursor-pointer">
            <label className="flex items-center cursor-pointer" for="input-img">
              {" "}
              <AiOutlineFileImage></AiOutlineFileImage>
              <span>Add Cover</span>
              <div className="mx-2 ">
                {isUploaded === "true" ? (
                  <AiFillCheckCircle className="text-green-500"></AiFillCheckCircle>
                ) : isUploaded === "false" ? (
                  <ImCross className="text-red-600"></ImCross>
                ) : (
                  ""
                )}
              </div>
            </label>
            <input
              onChange={uploadImageCallBack}
              className="w-[0]"
              accept="image/png, image/gif, image/jpeg"
              type="file"
              id="input-img"
            />
          </p>
        </p>
        {}
        <input
          onChange={(e) => setSubtitle(e.target.value)}
          className={` block mx-auto font-mono border-[#b1a8a8] w-[80%] rounded-md py-2 text-3xl placeholder:text-4xl my-3 px-3 ${
            isSubtitleClicked ? "" : "hidden"
          } `}
          placeholder="Subtitle"
          type="text"
        />
        {/* <p>{typeof (<b>HEllo</b>)}</p> */}
        <Select
          className="w-[80%]  my-3 placeholder:text-3xl block mx-auto z-[10] outline-black focus:outline-black"
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          onChange={(choice) => setUserChoice(choice)}
          styles={CustomStyle}
          placeholder="Select Category"
          options={options}
        />
        <h1 className="font-semibold text-[#e4d9d9] font-mono w-[80%] mx-auto text-3xl mb-3">
          Tell Your Story Here
        </h1>
        <div className="w-[80%] h-auto  mx-auto" id="editorjs"></div>
        {/* 
         function to convert the editor js to a raw data
*/}

        {/* <div className="mx-auto w-[80%]">
          {blocks.map((b) => {
            const d = b.data.text;
            //  const cd = JSON.parse(d);
            console.log(typeof cd);
            switch (b.type) {
              case "header":
                return <h3>{b.data.text}</h3>;
              case "paragraph":
                return <p>{parse(d)}</p>;
              case "list":
                return (
                  <ol className="list-decimal">
                    {b.data.items.map((e, index) => {
                      //console.log(e);
                      return (
                        <li>
                          <BsDot></BsDot>
                          {e}
                        </li>
                      );
                    })}
                  </ol>
                );
              case "delimiter":
                return <p className="text-2xl text-center">***</p>;
              case "table":
                return (
                  <table>
                    {b.data.content.map((arr, index) => {
                      if (index === 0)
                        return (
                          <thead>
                            {arr.map((a) => (
                              <th>{a}</th>
                            ))}
                          </thead>
                        );
                      else
                        return (
                          <tr>
                            {arr.map((a) => (
                              <td>{a}</td>
                            ))}
                          </tr>
                        );
                    })}
                    <tbody></tbody>
                  </table>
                );
              case "warning":
                return (
                  <p className="flex  ">
                    <p className="font-semibold flex items-center gap-2">
                      {" "}
                      <AiOutlineWarning className="text-red-600 font-semibold"></AiOutlineWarning>
                      {b.data.title} :{" "}
                    </p>

                    <p>{b.data.message}</p>
                  </p>
                );
              case "quote":
                return (
                  <ShowQuote
                    author={b.data.caption}
                    mainText={b.data.text}
                  ></ShowQuote>
                );
              case "code":
                return (
                  <Highlight className="bg-black " language="javascript">
                    {" "}
                    {b.data.code}
                  </Highlight>
                );
            }
          })}
        </div> */}
      </div>
      <Footer></Footer>
    </>
  );
};

export default Editor2;
