import React from "react";
import "swiper/swiper-bundle.min.css";
import "swiper/css/bundle";
import { Autoplay, Pagination } from "swiper";
// import "swiper/components/effect-flip/effect-flip.scss";
// import "swiper/components/scrollbar/scrollbar.scss";
import { Swiper, SwiperSlide } from "swiper/react";
const AllReviews = () => {
  return (
    <section className="testimonial-4 section-padding bg-gray">
      <div className="container flex justify-evenly">
        <div className="row flex items-center w-[30%] justify-content-center">
          <div className="">
            <div className="section-heading text-center mb-50">
              <h2 className="font-lg">Our Students Says</h2>
              <p>Discover Your Perfect Program In Our Courses.</p>
            </div>
          </div>
        </div>
        <div className="row w-[50%] align-items-center">
          <div className="col-lg-12 col-xl-12">
            <div className="testimonials-slides owl-carousel owl-theme">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#000",
                  "--swiper-pagination-color": "#000",
                }}
                slidesPerView={1}
                loop={true}
                pagination={{ clickable: true }}
                modules={[Pagination, Autoplay]}
                className={`mySwiper`}
                autoplay={{ delay: 2000 }}
              >
                <SwiperSlide>
                  {" "}
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="quote-icon">
                        <i className="flaticon-left-quote"></i>
                      </div>

                      <div className="testimonial-text mb-30">
                        Cras vel purus fringilla, lobortis libero ut Proin a
                        velit convallis, fermentum orci in, rutrum diam. Duis
                        elementum odio a pharetra commodo.
                      </div>

                      <div className="client-info d-flex align-items-center">
                        <div className="client-img">
                          <img src="" alt="" className="img-fluid" />
                        </div>
                        <div className="testimonial-author">
                          <h4>Cory Zamora</h4>
                          <span className="meta">Marketing Specialist</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="quote-icon">
                        <i className="flaticon-left-quote"></i>
                      </div>

                      <div className="testimonial-text  mb-30">
                        Cras vel purus fringilla, lobortis libero ut Proin a
                        velit convallis, fermentum orci in, rutrum diam. Duis
                        elementum odio a pharetra commodo.
                      </div>

                      <div className="client-info d-flex align-items-center">
                        <div className="client-img">
                          <img src="" alt="" className="img-fluid" />
                        </div>
                        <div className="testimonial-author">
                          <h4>Jackie Sanders</h4>
                          <span className="meta">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="quote-icon">
                        <i className="flaticon-left-quote"></i>
                      </div>

                      <div className="testimonial-text  mb-30">
                        Cras vel purus fringilla, lobortis libero ut Proin a
                        velit convallis, fermentum orci in, rutrum diam. Duis
                        elementum odio a pharetra commodo.
                      </div>

                      <div className="client-info d-flex align-items-center">
                        <div className="client-img">
                          <img src="" alt="" className="img-fluid" />
                        </div>
                        <div className="testimonial-author">
                          <h4>Nikolas Brooten</h4>
                          <span className="meta">Sales Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="quote-icon">
                        <i className="flaticon-left-quote"></i>
                      </div>

                      <div className="testimonial-text mb-30">
                        Cras vel purus fringilla, lobortis libero ut Proin a
                        velit convallis, fermentum orci in, rutrum diam. Duis
                        elementum odio a pharetra commodo.
                      </div>

                      <div className="client-info d-flex align-items-center">
                        <div className="client-img">
                          <img src="" alt="" className="img-fluid" />
                        </div>
                        <div className="testimonial-author">
                          <h4>Terry Ambady</h4>
                          <span className="meta">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="quote-icon">
                        <i className="flaticon-left-quote"></i>
                      </div>

                      <div className="testimonial-text mb-30">
                        Cras vel purus fringilla, lobortis libero ut Proin a
                        velit convallis, fermentum orci in, rutrum diam. Duis
                        elementum odio a pharetra commodo.
                      </div>

                      <div className="client-info d-flex align-items-center">
                        <div className="client-img">
                          <img src="" alt="" className="img-fluid" />
                        </div>
                        <div className="testimonial-author">
                          <h4>Nikolas Brooten</h4>
                          <span className="meta">Sales Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllReviews;
