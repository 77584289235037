import React from "react";

const Video = ({ videoLink, bgImage }) => {
  return (
    <div class="col-xl-5">
      <div class="course-thumb-wrap">
        <div class="course-thumbnail mb-0">
          <img src={bgImage} alt="" class="img-fluid w-100" />
        </div>

        <a class="popup-video video_icon" href={videoLink}>
          <i class="fal fa-play"></i>
        </a>
      </div>
    </div>
  );
};

export default Video;
