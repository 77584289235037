import React, { Component, useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AiOutlineBold } from "react-icons/ai";
import { useForm } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
const EditorComponent = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const options = [
    { value: "Java", label: "Java" },
    { value: "Javascript", label: "Javascript" },
    { value: "Python", label: "Python" },
    { value: "Rustc", label: "Rustc" },
    { value: "Web Development", label: "Web Development" },
    { value: "Competitive Programming", label: "Competitive Programming" },
    { value: "React", label: "React" },
    { value: "Machine Learning", label: "Machine Learning" },
    { value: "Artificial Intelligence", label: "Artificial Intelligence" },
    { value: "Cyber Security", label: "Cyber Security" },
    { value: "Cpp", label: "Cpp" },
    { value: "Data Structure", label: "Data Structure" },
    { value: "Algorithm", label: "Algorithm" },
    { value: "Neural Science", label: "Neural Science" },
    { value: "Graphs", label: "Graphs" },
  ];
  async function uploadImageCallBack(file) {
    var form = new FormData();
    form.append("image", file);
    var url =
      "https://api.imgbb.com/1/upload?key=6458ef300036886b2480e9e05257aae4";
    const config = {
      method: "POST",

      body: form,
    };
    const response = await fetch(url, config);
    //return response;
    const json = await response.json();

    console.log(json.data.display_url);
    //  return json.data.display_url;
    return new Promise((resolve, reject) => {
      resolve({ data: { link: `${json.data.display_url}` } });
    });
  }
  const CustomStyle = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
      color: "black",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#000" : isSelected ? "#fff" : undefined,
      color: isFocused ? "#fff" : isSelected ? "#000" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <div>
      <h1 className="text-4xl font-semibold text-center my-5">
        Write Your New Blog
      </h1>
      <input
        className="border-2 block mx-auto border-[black] w-[80%] rounded-sm py-2 text-2xl placeholder:text-2xl my-3 px-2"
        placeholder="Title"
        type="text"
      />
      <Select
        className="w-[80%] placeholder:text-2xl block mx-auto z-[10000] outline-black focus:outline-black"
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        styles={CustomStyle}
        placeholder="Select Category"
        options={options}
      />
      {/* <div className="flex justify-end w-[80%] mx-auto my-8">
        <button className=" mx-2 btn btn-main-2 btn-sm-2 rounded font-semibold hover:text-[#F14D5D] bg-[#015ABD] text-[white] ">
          Publish Your Blog
        </button>
      </div> */}
      {/* <Editor editorState={editorState} onEditorStateChange={setEditorState} /> */}
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={setEditorState}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },

          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: true },
            className: "imageDropdown",
          },
        }}
      />
      <div className="my-20 w-[80%] flex mx-auto justify-end">
        <button className=" mx-2 btn btn-main-2 btn-sm-2 rounded font-semibold hover:text-[#F14D5D] bg-[#015ABD] text-[white] ">
          Publish Your Blog
        </button>
      </div>
     
      <textarea
        className="w-[100%] my-20 border-[1px] border-black p-3"
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      />
    </div>
  );
};

export default EditorComponent;
// function EditorComponent= () => {

//     return (
//
//     );
//   };

// export default EditorComponent;
