import logo from "./logo.svg";
import "./App.css";
import Index from "./Components/Pages/Index/Home";
import Home from "./Components/Pages/Index/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from "./Components/Pages/About/About";
import Home2 from "./Components/Pages/Index/Home2";
import Home3 from "./Components/Pages/Index/Home3";
import Home4 from "./Components/Pages/Index/Home4";
import Instructors from "./Components/Pages/Instructors/Instructors";
import Cart from "./Components/Pages/Cart/Cart";
import Checkout from "./Components/Pages/Checkout/Checkout";
import Login from "./Components/Pages/Login/Login";
import Register from "./Components/Pages/Register/Register";
import NotFound from "./Components/Pages/NotFound/NotFound";
import Contact from "./Components/Pages/Contact/Contact";
import Blog from "./Components/Pages/Blog/Blog";
import Blockgrid from "./Components/Pages/Blog/Bloggrid";
import Bloggrid from "./Components/Pages/Blog/Bloggrid";
import BlogDetails from "./Components/Pages/Blog/BlogDetails";
import Courses1 from "./Components/Pages/Courses/Courses1";
import CoursesLayout2 from "./Components/Pages/Courses/CoursesLayout2";
import Courses2 from "./Components/Pages/Courses/Courses2";
import Courses3 from "./Components/Pages/Courses/Courses3";
import CoursesList from "./Components/Pages/Courses/CoursesList";
import SingleCourseLayout from "./Components/Pages/Courses/SingleCourses/SingleCourseLayout";
import Overview from "./Components/Pages/Courses/SingleCourses/SIngleCourseComponent/Overview";
import CourseInstructor from "./Components/Pages/Courses/SingleCourses/SIngleCourseComponent/CourseInstructor";
import CourseReview from "./Components/Pages/Courses/SingleCourses/SIngleCourseComponent/CourseReview";
import Curriculam from "./Components/Pages/Courses/SingleCourses/SIngleCourseComponent/Curriculam";
import Header from "./Components/Pages/Shared/Header/Header";
import AllReviews from "./Components/Pages/Reviews/AllReviews";
import Loader from "./Components/Pages/Shared/Loader/Loader";
import Editor from "./Components/Pages/Editor/EditorComponent";
import EditorComponent from "./Components/Pages/Editor/EditorComponent";
import Editor2 from "./Components/Pages/Editor/Editor2";
import Blog_2 from "./Components/Pages/Blog/Blog_2";
const routes = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
  },
  {
    path: "/home",
    element: <Home></Home>,
  },
  {
    path: "/about",
    element: <About></About>,
  },
  {
    path: "/home2",
    element: <Home2></Home2>,
  },
  {
    path: "/home3",
    element: <Home3></Home3>,
  },
  {
    path: "/home4",
    element: <Home4></Home4>,
  },
  {
    path: "/instructors",
    element: <Instructors></Instructors>,
  },
  {
    path: "/cart",
    element: <Cart></Cart>,
  },
  {
    path: "/checkout",
    element: <Checkout></Checkout>,
  },
  {
    path: "/login",
    element: <Login></Login>,
  },
  {
    path: "/register",
    element: <Register></Register>,
  },
  {
    path: "/contact",
    element: <Contact></Contact>,
  },
  {
    path: "/blog",
    element: <Blog></Blog>,
  },
  {
    path: "/blog2",
    element: <Blog_2></Blog_2>,
  },
  {
    path: "/blog-grid",
    element: <Bloggrid></Bloggrid>,
  },
  {
    path: "/blogDetails/:index",
    element: <BlogDetails></BlogDetails>,
  },
  {
    path: "/courses1",
    element: <Courses1></Courses1>,
  },
  {
    path: "/courses2",
    element: <Courses2></Courses2>,
  },
  {
    path: "/courses3",
    element: <Courses3></Courses3>,
  },
  {
    path: "/coursesList",
    element: <CoursesList></CoursesList>,
  },
  {
    path: "/singleCourseLayout",
    element: <SingleCourseLayout></SingleCourseLayout>,
    children: [
      {
        path: "courseOverview",
        element: <Overview></Overview>,
      },
      {
        path: "courseInstructor",
        element: <CourseInstructor></CourseInstructor>,
      },
      {
        path: "courseReview",
        element: <CourseReview></CourseReview>,
      },
      {
        path: "courseCurriculum",
        element: <Curriculam></Curriculam>,
      },
    ],
  },
  {
    path: "courseOverview",
    element: <Overview></Overview>,
  },
  {
    path: "courseInstructor",
    element: <CourseInstructor></CourseInstructor>,
  },
  {
    path: "courseReview",
    element: <CourseReview></CourseReview>,
  },
  {
    path: "courseCurriculum",
    element: <Curriculam></Curriculam>,
  },
  {
    path: "allReviews",
    element: <AllReviews></AllReviews>,
  },
  {
    path: "loader",
    element: <Loader></Loader>,
  },
  {
    path: "editor",
    element: <EditorComponent></EditorComponent>,
  },
  {
    path: "editor2",
    element: <Editor2></Editor2>,
  },
  {
    path: "*",
    element: <NotFound></NotFound>,
  },
]);
function App() {
  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
