import React from "react";

const CoursesLayout3 = ({
  img1,
  img2,
  price,
  instructor,
  title,
  duration,
  lectureNumber,
  studentNumber,
}) => {
  return (
    <div class="col-xl-4 col-lg-4 col-md-6">
      <div class="course-grid bg-shadow tooltip-style">
        <div class="course-header">
          <div class="course-thumb">
            <img src={img1} alt="" class="img-fluid" />
            <div class="course-price">$300</div>
          </div>
        </div>

        <div class="course-content">
          <div class="rating mb-10">
            <i class="fa fa-star"></i>
            <i class="fa fa-star"></i>
            <i class="fa fa-star"></i>
            <i class="fa fa-star"></i>
            <i class="fa fa-star"></i>

            <span>3.9 (30 reviews)</span>
          </div>

          <h3 class="course-title mb-20">
            {" "}
            <a href="#">{title} </a>{" "}
          </h3>

          <div class="course-footer mt-20 d-flex align-items-center justify-content-between ">
            <span class="duration">
              <i class="far fa-clock me-2"></i>
              {duration} hr
            </span>
            <span class="students">
              <i class="far fa-user-alt me-2"></i>
              {studentNumber} Students
            </span>
            <span class="lessons">
              <i class="far fa-play-circle me-2"></i>
              {lectureNumber} Lessons
            </span>
          </div>
        </div>

        <div class="course-hover-content">
          <div class="price">${price}</div>
          <h3 class="course-title mb-20 mt-30">
            {" "}
            <a href="#">{title} </a>{" "}
          </h3>
          <div class="course-meta d-flex align-items-center mb-20">
            <div class="author me-4">
              <img src={img2} alt="" class="img-fluid" />
              <a href="#">{instructor}</a>
            </div>
            <span class="lesson">
              {" "}
              <i class="far fa-file-alt"></i> {lectureNumber} lessons
            </span>
          </div>
          <p class="mb-20">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero,
            culpa. At voluptatem autem ipsam deleniti
          </p>
          <a href="#" class="btn btn-grey btn-sm rounded">
            Join Now <i class="fal fa-angle-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CoursesLayout3;
