import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logopik from "./../../../../Images/logo.png";
import { BiChevronDown } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { AiOutlineBars, AiOutlineClose } from "react-icons/ai";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import swal from "sweetalert";
import auth from "../../../../firebase.config";
import { CgProfile } from "react-icons/cg";
export default function Header() {
  const [isClicked, setClicked] = useState(false);
  const [isclickedCtg, setclickedCtg] = useState(false);
  const [showCat, setShowCat] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const [showBlog, setShowBlog] = useState(false);
  const [showPages, setShowPages] = useState(false);
  // let newStyle = "header-style-"+styleno;
  useEffect(() => {
    if (isClicked) console.log("Bar is clicked");
  }, [isClicked]);
  const [signOut, loading1, error1] = useSignOut(auth);
  const [user, loading, error] = useAuthState(auth);

  return (
    <header class="header-style-1 relative">
      <div class="header-topbar z-10 w-[100%] fixed top-[0px] topbar-style-1">
        <div class="container ">
          <div class="row justify-content-between align-items-center">
            <div class="col-xl-8 col-lg-8 col-sm-6">
              <div class="header-contact text-center text-sm-start text-lg-start">
                <a href="#">1010 Moon ave, New York, NY US</a>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-sm-6">
              <div class="header-socials text-center text-lg-end">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fab fa-Linkedin-in"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="lg:grid w-[100%] fixed top-[35px] z-10 bg-[white] flex lg:grid-cols-5 py-1 text-sm align-items-center justify-content-between">
          {/* Logo */}
          <div class="site-logo  mx-3  col-span-1  lg:w-[60%] w-[150px]">
            <Link classroom="txt text-[black]" className="w-[100%]" to="/home">
              <img src={logopik} alt="" class="w-[100%]" />
            </Link>
            <div class="header-category-menu d-none d-xl-block"></div>
          </div>
          {/* Bar */}

          {/* Categories Portion */}

          {/* Main Route Portion */}
          <nav class="col-span-3 text-[black] font-semibold hidden lg:flex ">
            <ul class="primary-menu w-[100%] justify-evenly flex">
              <ul>
                <li class=" mb-0   relative">
                  <a
                    className="text-[black]"
                    href="#"
                    onClick={() => setclickedCtg(!isclickedCtg)}
                  >
                    <span className="flex items-center">
                      <i class="fa fa-th me-2"></i>Categories
                      <BiChevronDown className="text-[black]"></BiChevronDown>
                    </span>
                  </a>
                  <ul
                    onMouseOver={() => setclickedCtg(true)}
                    onMouseOut={() => setclickedCtg(false)}
                    class={`submenu my-0 duration-300 ${
                      isclickedCtg ? "" : "hidden"
                    } shadow-xl rounded-md absolute z-40 bg-white  border-[black] border-2 py-2 px-6 top-7 w-[200px]`}
                  >
                    <li>
                      <a href="#">Design</a>
                      <ul class="submenu hidden">
                        <li>
                          <a href="#">Design Tools</a>
                        </li>
                        <li>
                          <a href="#">Photoshop mastering</a>
                        </li>
                        <li>
                          <a href="#">Adobe Xd Deisgn</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Developemnt</a>
                    </li>
                    <li>
                      <a href="#">Marketing</a>
                    </li>
                    <li>
                      <a href="#">Freelancing</a>
                    </li>
                  </ul>
                </li>
              </ul>
              <li class="current">
                <Link classroom="txt text-[black]" to="/home">
                  Home
                </Link>
                <ul class="submenu  hidden">
                  <li>
                    <Link classroom="txt text-[black]" to="/home">
                      Home One
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/home2">
                      Home Two
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/home3">
                      Home Three
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/home4">
                      Home Four
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link classroom="txt text-[black]" to="/about">
                  About
                </Link>
              </li>

              <li>
                <a href="courses.html">Courses</a>
                <ul class="submenu hidden">
                  <li>
                    <Link classroom="txt text-[black]" to="/courses1">
                      Course Grid 1
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/courses2">
                      Course Grid 2
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/courses3">
                      Course Grid 3
                    </Link>
                  </li>

                  <li>
                    <Link classroom="txt text-[black]" to="/coursesList">
                      Course List
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/singleCourseLayout">
                      Single Course
                    </Link>
                    {/* <ul class="submenu hidden">

                        {/* <li>
                          <a href="course-single.html">Course Single 1</a>
                        </li>
                        <li>
                          <a href="course-single-2.html">Course Single 2</a>
                        </li>
                        <li>
                          <a href="course-single-3.html">Course Single 3</a>
                        </li>
                        <li>
                          <a href="course-single-4.html">Course Single 4</a>
                        </li>
                        <li>
                          <a href="course-single-5.html">Course Single 5</a>
                        </li> */}
                    {/* //</li> </ul> */}
                  </li>
                </ul>
              </li>

              <li>
                <a href="#">Pages</a>
                <ul class="submenu hidden">
                  <li>
                    <Link classroom="txt text-[black]" to="/instructors">
                      Instructors
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/cart">
                      Cart
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/checkout">
                      Checkout
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/login">
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/register">
                      Register
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link classroom="txt text-[black]" to="/blog">
                  Blog
                </Link>

                <ul class="submenu hidden">
                  <li>
                    <Link classroom="txt text-[black]" to="/blog-grid">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link classroom="txt text-[black]" to="/blogDetails">
                      Blog Details
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link classroom="txt text-[black]" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>

            {/* <a href="#" class="nav-close">
                <i class="fal fa-times"></i>
              </a> */}
          </nav>

          {/* Bar and cross button */}
          <div className="d-block mx-3 d-lg-none">
            <div class="offcanvas-icon border-[black] h-[40px] border-2  w-[50px] relative ">
              <Link
                onClick={() => setClicked(!isClicked)}
                className={`w-[50px]  top-0 border-2 border-black h-[40px] flex justify-center ${
                  isClicked ? "absolute" : "hidden"
                } items-center bg-black`}
              >
                <AiOutlineClose className="font-bold h-[30px] w-[35px]  text-white"></AiOutlineClose>

                {/* <ImCross className="font-bold text-2xl text-white"></ImCross> */}
              </Link>

              <Link
                onClick={() => setClicked(!isClicked)}
                class={`w-[50px]  top-0 border-2 border-black h-[40px] flex justify-center ${
                  isClicked ? "hidden" : "absolute"
                } items-center bg-black`}
              >
                <AiOutlineBars className="h-[30px] w-[35px]  font-bold  text-white"></AiOutlineBars>
              </Link>
            </div>
          </div>

          {/* Login And Register Portion */}
          <div class="header-btn col-span-1 d-none d-xl-block">
            {user ? (
              <div className="flex items-center">
                {" "}
                <Link to="/profile">
                  <CgProfile className="text-3xl cursor-pointer text-black mx-2"></CgProfile>
                </Link>
                <Link
                  onClick={async () => {
                    const success = await signOut();
                    if (success) {
                      swal("", "You are sign out", "info");
                      // alert("");
                    }
                  }}
                  className="btn btn-main-2 btn-sm-2 rounded"
                >
                  Sign Out
                </Link>
              </div>
            ) : (
              <>
                <Link
                  classroom="text-[black]"
                  className="mx-2 font-semibold hover:text-[#F14D5D] text-[black]"
                  to="/login"
                >
                  Login
                </Link>
                <Link
                  classroom="text-[black]"
                  to="/register"
                  className="btn btn-main-2 btn-sm-2 rounded"
                >
                  Sign up
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <nav
        class={`col-span-3 fixed top-[0px] right-0  z-[1000]
        duration-1000
        lg:translate-y-[500px]
        ${
          isClicked ? "translate-x-[0px]" : "translate-x-[500px]"
        } overflow-hidden  border-2 border-black w-[200px] h-auto md:w-[350px] bg-black text-white min-h-[150vh]  font-semibold  lg:flex `}
      >
        <ul class="primary-menu w-[100%] justify-evenly items-center py-3 flex-col gap-y-0 h-[50%]   flex">
          <div className="flex mb-9 justify-end w-[100%]">
            <span
              onClick={() => setClicked(!isClicked)}
              className="text-white cursor-pointer block mr-3"
            >
              {" "}
              X
            </span>
          </div>
          <ul className="w-[100%] mb-0 ">
            <li class=" mb-0 w-[100%] py-1 text-sm flex justify-center flex-col items-center  border-[1px] border-[#909090] relative">
              <Link className="text-white " href="#">
                <span className="flex justify-between items-center">
                  Categories
                  <BiChevronDown
                    onClick={() => setShowCat(!showCat)}
                    className={`text-[white]  ${
                      showCat ? "rotate-180" : "rotate-0"
                    }`}
                  ></BiChevronDown>
                </span>
              </Link>
            </li>
          </ul>
          <ul
            class={`submenu my-0 duration-1000
                 shadow-xl rounded-md border-[1px] border-white transition-all   ${
                   showCat ? "translate-y-0" : "-translate-y-[500px]"
                 } ${showCat ? "" : "h-[0px]"}  w-[100%]`}
          >
            <li className="text-white next ">
              <a className="text-white" href="#">
                Design
              </a>
              <ul class="submenu hidden">
                <li>
                  <a className="text-white" href="#">
                    Design Tools
                  </a>
                </li>
                <li>
                  <a className="text-white" href="#">
                    Photoshop mastering
                  </a>
                </li>
                <li>
                  <a className="text-white" href="#">
                    Adobe Xd Deisgn
                  </a>
                </li>
              </ul>
            </li>
            <li className="next">
              <a className="text-white" href="#">
                Developemnt
              </a>
            </li>
            <li className="next">
              <a className="text-white" href="#">
                Marketing
              </a>
            </li>
            <li className="next">
              <a className="text-white" href="#">
                Freelancing
              </a>
            </li>
          </ul>
          <li class=" w-[100%] py-1 text-sm flex justify-center  border-[1px] border-t-0 border-b-0 border-[#909090]">
            <Link classroom="text-[white]" to="/home">
              <div className="flex items-center">
                <span className="text-white">Home</span>
                <BiChevronDown
                  onClick={() => {
                    //console.log(showCat);
                    if (showCat === true && showHome === false) {
                      setShowCat(!showCat);
                    }
                    setShowHome(!showHome);
                  }}
                  className={`text-[white]  ${
                    showHome ? "rotate-180" : "rotate-0"
                  }`}
                ></BiChevronDown>
              </div>
            </Link>
          </li>
          <ul
            class={`submenu my-0 duration-1000
                 shadow-xl rounded-md border-[1px] border-[#5f5e5e] transition-all   ${
                   showHome ? "translate-y-0" : "-translate-y-[500px]"
                 } ${showHome ? "" : "h-[0px]"}  w-[100%]`}
          >
            <li className="text-white next ">
              <a className="text-white" href="#">
                <Link to="/home">
                  <span className="text-[white]">Home One</span>
                </Link>
              </a>
            </li>
            <li>
              <Link className="next text-[white]" to="/home2">
                Home Two
              </Link>
            </li>
            <li>
              <Link className="next text-[white]" to="/home3">
                Home Three
              </Link>
            </li>
            <li>
              <Link className="next bbb text-[white]" to="/home4">
                Home Four
              </Link>
            </li>
          </ul>
          <li className="w-[100%] py-1 text-sm flex justify-center  border-[1px] border-[#909090]">
            <Link classroom="text-[white]" to="/about">
              <span className="text-white">About</span>
            </Link>
          </li>

          <li className="w-[100%] py-1 text-sm flex justify-center  border-[1px] border-b-0 border-[#909090]">
            {" "}
            <div className="flex  items-center">
              <span className="text-white">Courses</span>{" "}
              <BiChevronDown
                onClick={() => {
                  //console.log(showCat);
                  if (
                    (showCat === true || showHome === true) &&
                    showCourses === false
                  ) {
                    if (showCat === true) setShowCat(!showCat);
                    if (showHome === true) setShowHome(!showHome);
                  }
                  setShowCourses(!showCourses);
                }}
                className={`text-[white] cursor-pointer  ${
                  showCourses ? "rotate-180" : "rotate-0"
                }`}
              ></BiChevronDown>
            </div>
          </li>
          <ul
            class={`submenu my-0 duration-1000
                 shadow-xl rounded-md border-[1px] border-[#5f5e5e] transition-all   ${
                   showCourses ? "translate-y-0" : "-translate-y-[500px]"
                 } ${showCourses ? "" : "h-[0px]"}  w-[100%]`}
          >
            <li className="text-white next ">
              <a className="text-white" href="#">
                <Link className="next text-[white]" to="/courses1">
                  Course Grid 1
                </Link>
              </a>
            </li>
            <li>
              <Link className="next text-[white]" to="/courses2">
                Course Grid 2
              </Link>
            </li>
            <li>
              <Link className="next text-[white]" to="/courses3">
                Course Grid 3
              </Link>
            </li>
            <li>
              <Link className="next text-[white]" to="/coursesList">
                Course List
              </Link>
            </li>
            <li>
              <Link className="next text-[white]" to="/singleCourseLayout">
                Single Course
              </Link>
            </li>
          </ul>
          {/* <ul
            class={`submenu my-0 duration-700
                 shadow-xl rounded-md border-[1px] border-white transition-all   ${
                   showCat ? "translate-y-0" : "-translate-y-[500px]"
                 } ${showCat ? "" : "h-[0px]"}  w-[100%]`}
          >
            <li className="text-white next ">
              <a className="text-white" href="#">
                Design
              </a>
              <ul class="submenu hidden">
                <li>
                  <a className="text-white" href="#">
                    Design Tools
                  </a>
                </li>
                <li>
                  <a className="text-white" href="#">
                    Photoshop mastering
                  </a>
                </li>
                <li>
                  <a className="text-white" href="#">
                    Adobe Xd Deisgn
                  </a>
                </li>
              </ul>
            </li>
            <li className="next">
              <a className="text-white" href="#">
                Developemnt
              </a>
            </li>
            <li className="next">
              <a className="text-white" href="#">
                Marketing
              </a>
            </li>
            <li className="next">
              <a className="text-white" href="#">
                Freelancing
              </a>
            </li>
          </ul> */}
          <li className="w-[100%] py-1 text-sm flex justify-center  border-[.5px] border-[#909090]">
            <div className="flex items-center">
              <span className="text-white">Pages</span>
              <BiChevronDown className="text-[white]"></BiChevronDown>
            </div>
            <ul class="submenu hidden">
              <li>
                <Link classroom="text-[white]" to="/instructors">
                  Instructors
                </Link>
              </li>
              <li>
                <Link classroom="text-[white]" to="/cart">
                  Cart
                </Link>
              </li>
              <li>
                <Link classroom="text-[white]" to="/checkout">
                  Checkout
                </Link>
              </li>
              <li>
                <Link classroom="text-[white]" to="/login">
                  Login
                </Link>
              </li>
              <li>
                <Link classroom="text-[white]" to="/register">
                  Register
                </Link>
              </li>
            </ul>
          </li>

          <li className="w-[100%] py-1 text-sm flex justify-center  border-[1px] border-[#909090]">
            <Link classroom="text-[white]" to="/blog">
              <div className="flex items-center">
                {" "}
                <span className="text-white">Blog</span>
                <BiChevronDown className="text-[white]"></BiChevronDown>
              </div>
            </Link>

            <ul class="submenu hidden">
              <li>
                <Link classroom="text-[white]" to="/blog-grid">
                  Blog
                </Link>
              </li>
              <li>
                <Link classroom="text-[white]" to="/blogDetails">
                  Blog Details
                </Link>
              </li>
            </ul>
          </li>
          <li className="w-[100%] py-1 text-sm flex justify-center  border-[1px] border-[#909090]">
            <Link classroom="text-[white] flex items-center" to="/contact">
              <div className="flex items-center">
                <span className="text-white">Contact</span>{" "}
                <BiChevronDown className="text-[white]"></BiChevronDown>
              </div>
            </Link>
          </li>
        </ul>
        <div class="flex flex-col relative bottom-0 ">
          {user ? (
            <div className="flex items-center">
              {" "}
              <Link to="/profile">
                <CgProfile className="text-3xl cursor-pointer text-white mx-2"></CgProfile>
              </Link>
              <Link
                onClick={async () => {
                  const success = await signOut();
                  if (success) {
                    swal("", "You are sign out", "info");
                    // alert("");
                  }
                }}
                className="btn btn-main-2 btn-sm-2 rounded"
              >
                Sign Out
              </Link>
            </div>
          ) : (
            <Link
              className="mx-2 btn btn-main-2 btn-sm-2 rounded font-semibold hover:text-[#F14D5D] bg-[#015ABD] text-[white]"
              to="/login"
            >
              Login
            </Link>
          )}
        </div>
        {/* <a href="#" class="nav-close">
                <i class="fal fa-times"></i>
              </a> */}
      </nav>
    </header>
  );
}
