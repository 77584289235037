import React from "react";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";

const CoursesLayout2 = ({
  courseImage,
  authorImage,
  category,
  level,
  courseTitle,
  author,
  totalStudents,
  price,
}) => {
  return (
    <div class="col-xl-4 col-lg-4 col-md-6">
      <div class="course-grid course-style-3">
        <div class="course-header">
          <div class="course-thumb">
            <img src={courseImage} alt="" class="img-fluid" />
          </div>
        </div>

        <div class="course-content">
          <div class="course-meta d-flex justify-content-between mb-20">
            <span class="category">{category}</span>
            <span class="label">
              <i class="fas fa-signal me-2"></i>
              {level}
            </span>
          </div>
          <h3 class="course-title mb-20">
            {" "}
            <a href="#">{courseTitle} </a>{" "}
          </h3>

          <div class="course-meta-info">
            <div class="d-flex align-items-center">
              <div class="author me-3">
                <img src={authorImage} alt="" class="img-fluid" />
                By <a href="#">{author}</a>
              </div>
              <span class="students">
                <i class="far fa-user-alt me-2"></i>
                {totalStudents} Students
              </span>
            </div>
          </div>

          <div class="course-footer mt-20 d-flex align-items-center justify-content-between">
            <div class="course-price">${price}</div>
            <a href="#" class="btn btn-main-outline btn-radius btn-sm">
              Buy Now <i class="fa fa-long-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesLayout2;
