import React from "react";

import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdb-react-ui-kit";
const ShowQuote = ({ author, mainText }) => {
  return (
    <section>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="align-items-center h-100">
          <MDBCol lg="6" className="mb-4 mb-lg-4">
            <figure
              className="bg-white p-3 rounded"
              style={{ borderLeft: ".25rem solid #015ABD" }}
            >
              <MDBTypography blockquote className="pb-2 ">
                <p>{mainText}</p>
              </MDBTypography>
              <figcaption className="blockquote-footer mb-0">
                {author}
              </figcaption>
            </figure>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
};

export default ShowQuote;
