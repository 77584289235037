import React, { useState } from "react";
import { useEffect } from "react";
import { BsDot } from "react-icons/bs";
import parse from "html-react-parser";
import Highlight from "react-highlight";
import ShowQuote from "../Shared/Quote/ShowQuote";
import { BsBookmarkPlusFill } from "react-icons/bs";
import {
  AiOutlineFileImage,
  AiOutlineWarning,
  AiFillCheckCircle,
} from "react-icons/ai";
import { Link } from "react-router-dom";
/**
 * Structure of props
 * blog will have some properties
 *
 *
 * blocks : type Array will contain all the content of text editor
 * cover : type String will contain a link to the cover photo
 * title : type String will contain a title
 * subtitle :
 * userchoice : array will contain the tags
 * @param {*} param0
 * @returns
 */
const BlogCart = ({ blocks, cover, title, subtitle, userChoice, index }) => {
  //   const [choice, setChoice] = useState([]);
  //   let c;
  //   for (let i = 0; i < userChoice.length; i++) {
  //     c?.push(userChoice[i].value);
  //   }
  //   setChoice(c);
  return (
    <div className="border-[1px] rounded-lg border-gray-300 p-2 shadow-lg">
      <Link to={`/blogDetails/${index}`}>
        <h1 className="text-3xl text-center">{title}</h1>
      </Link>

      <img
        className="w-[80%] block mx-auto object-cover h-[300px]"
        src={cover}
        alt=""
      />
      <h5>{subtitle}</h5>
      {/* Author information and publish time */}
      <div className="flex justify-between w-[80%] mx-auto">
        <div className="flex justify-center items-center gap-2 my-2">
          <img
            className="border-[1px] cursor-pointer w-[50px] h-[50px] rounded-full object-cover border-black"
            src=""
            alt="author"
          />
          <p className="font-semibold">March 3,2023</p>
        </div>
        <div className="flex justify-center items-center gap-2 my-2">
          <p>12 min read</p>

          <p className="cursor-pointer">
            <BsBookmarkPlusFill></BsBookmarkPlusFill>
          </p>
        </div>
      </div>

      <div className="mx-auto break-words h-[120px] overflow-hidden w-[80%]">
        {blocks.map((b) => {
          const d = b.data.text;
          //  const cd = JSON.parse(d);
          console.log(typeof cd);
          switch (b.type) {
            case "header":
              return <h3>{b.data.text}</h3>;
            case "paragraph":
              return <p>{parse(d)}</p>;
            case "list":
              return (
                <ol className="list-decimal">
                  {b.data.items.map((e, index) => {
                    //console.log(e);
                    return (
                      <li>
                        <BsDot></BsDot>
                        {e}
                      </li>
                    );
                  })}
                </ol>
              );
            case "delimiter":
              return <p className="text-2xl text-center">***</p>;
            case "table":
              return (
                <table>
                  {b.data.content.map((arr, index) => {
                    if (index === 0)
                      return (
                        <thead>
                          {arr.map((a) => (
                            <th>{a}</th>
                          ))}
                        </thead>
                      );
                    else
                      return (
                        <tr>
                          {arr.map((a) => (
                            <td>{a}</td>
                          ))}
                        </tr>
                      );
                  })}
                  <tbody></tbody>
                </table>
              );
            case "warning":
              return (
                <p className="flex  ">
                  <p className="font-semibold flex items-center gap-2">
                    {" "}
                    <AiOutlineWarning className="text-red-600 font-semibold"></AiOutlineWarning>
                    {b.data.title} :{" "}
                  </p>

                  <p>{b.data.message}</p>
                </p>
              );
            case "quote":
              return (
                <ShowQuote
                  author={b.data.caption}
                  mainText={b.data.text}
                ></ShowQuote>
              );
            case "code":
              return (
                <Highlight className="bg-black " language="javascript">
                  {" "}
                  {b.data.code}
                </Highlight>
              );
          }
        })}
      </div>
      <div>
        <p className="flex gap-2 w-[80%] mx-auto flex-wrap  ">
          <a className="  text-sm bg-[#E6E6E6] px-1 rounded-lg" href="">
            {userChoice[0]?.value}
          </a>
          <a className="text-sm bg-[#E6E6E6] px-1 rounded-lg" href="">
            {userChoice[1]?.value}
          </a>
          <a className="text-sm bg-[#E6E6E6] px-1 rounded-lg" href="">
            {userChoice[2]?.value}
          </a>
        </p>
      </div>
    </div>
  );
};

export default BlogCart;
