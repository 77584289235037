import React from "react";
//Importing styles
// import "./../../../Vendors/animated-headline/animated-headline.css";
// import "./../../../Vendors/animated-headline/animated-headline";
// import "./../../../Vendors/owl/owl.carousel.min.js";
// //import './../../../Css/'
//Importing images
import logopik from "./../../../Images/logo.png";
import bannerImg from "./../../../Images/banner/banner_img.jpg";
import icon6 from "./../../../Images/icon/icon6.png";
import icon1 from "./../../../Images/icon/icon1.png";
import icon2 from "./../../../Images/icon/icon2.png";
import course2 from "./../../../Images/course/course-2.jpg";
import courseImg01 from "./../../../Images/course/img_01.jpg";
import courseImg02 from "./../../../Images/course/img_02.jpg";
import courseImg03 from "./../../../Images/course/img_03.jpg";
import courseImg06 from "./../../../Images/course/img_06.jpg";
import office01 from "./../../../Images/bg/cta-bg.jpg";
import avata01 from "./../../../Images/clients/testimonial-avata-01.jpg";
import avata02 from "./../../../Images/clients/testimonial-avata-02.jpg";
import avata03 from "./../../../Images/clients/testimonial-avata-03.jpg";
import avata04 from "./../../../Images/clients/testimonial-avata-04.jpg";
import team1 from "./../../../Images/team/team-1.jpg";
import team2 from "./../../../Images/team/team-2.jpg";
import team3 from "./../../../Images/team/team-3.jpg";
import team4 from "./../../../Images/team/team-4.jpg";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
const Home2 = () => {
  return (
    <div id="top-header">
      <Header></Header>
      {/* <!--====== Header End ======-->
<!-- Banner Section Start --> */}
      <section class="banner-style-2 ">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-12 col-xl-8 col-lg-10">
              <div class="banner-content text-center">
                <span class="subheading">Join the Millions Learning</span>
                <h1 class="cd-headline clip is-full-width">
                  Learn how to
                  <span class="cd-words-wrapper">
                    <b class="is-visible">design</b>
                    <b>coding</b>
                    <b>develop</b>
                  </span>
                </h1>

                <p>
                  Choose from over 100,000 online video courses with new
                  additions published every month
                </p>

                <div class="btn-container">
                  <a href="#" class="btn btn-main rounded">
                    Find Courses
                  </a>
                  <a href="#" class="btn btn-white rounded ms-2">
                    Get started{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / .row --> */}
        </div>
        {/* <!-- / .container --> */}
      </section>
      {/* <!-- Banner Section End -->

<!-- Feature section start --> */}
      <section class="features-2">
        <div class="container">
          <div class="row ">
            <div class="col-lg-3 col-md-6 col-xl-3 col-sm-6">
              <div class="feature-item feature-style-top mb-4 mb-lg-0">
                <div class="feature-icon">
                  <i class="flaticon-teacher"></i>
                </div>
                <div class="feature-text">
                  <h4>Expert Teacher</h4>
                  <p>
                    Develop skills for career of various majors including
                    computer
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-xl-3 col-sm-6">
              <div class="feature-item feature-style-top mb-4 mb-lg-0">
                <div class="feature-icon">
                  <i class="flaticon-layer"></i>
                </div>
                <div class="feature-text">
                  <h4>Self Development</h4>
                  <p>
                    Develop skills for career of various majors including
                    computer.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-xl-3 col-sm-6">
              <div class="feature-item feature-style-top mb-4 mb-lg-0">
                <div class="feature-icon">
                  <i class="flaticon-video-camera"></i>
                </div>
                <div class="feature-text">
                  <h4>Remote Learning</h4>
                  <p>
                    Develop skills for career of various majors including
                    language
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-xl-3 col-sm-6">
              <div class="feature-item feature-style-top">
                <div class="feature-icon">
                  <i class="flaticon-lifesaver"></i>
                </div>
                <div class="feature-text">
                  <h4>Life Time Support</h4>
                  <p>
                    Develop skills for career of various majors including
                    language{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Feature section End -->
<!-- Feature Section Start --> */}
      <section class="features section-padding-btm">
        <div class="container">
          <div class="row align-items-center justify-content-end mb-50">
            <div class="col-xl-6 pe-xl-5 col-lg-6">
              <img src={bannerImg} alt="" class="img-fluid" />
            </div>

            <div class="col-xl-6 col-lg-6 ">
              <div class="section-heading mt-5 mt-lg-0 mb-4">
                <span class="subheading">10 years Glory of success</span>
                <h2 class="mb-20 font-lg">
                  Constructive learning perspective{" "}
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perferendis recusandae reiciendis cumque, sequi vitae illum
                  dolorem
                </p>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-course-category style-2">
                    <div class="course-cat-icon">
                      <img src={icon6} alt="" class="img-fluid" />
                    </div>
                    <div class="course-cat-content">
                      <h4 class="course-cat-title">
                        <a href="#">Web Development</a>
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-course-category style-2 ">
                    <div class="course-cat-icon">
                      <img src={icon1} alt="" class="img-fluid" />
                    </div>
                    <div class="course-cat-content">
                      <h4 class="course-cat-title">
                        <a href="#">Data Science & Analytics</a>
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-course-category style-2">
                    <div class="course-cat-icon">
                      <img src={icon6} alt="" class="img-fluid" />
                    </div>
                    <div class="course-cat-content">
                      <h4 class="course-cat-title">
                        <a href="#">Web Development</a>
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="single-course-category style-2">
                    <div class="course-cat-icon">
                      <img src={icon2} alt="" class="img-fluid" />
                    </div>
                    <div class="course-cat-content">
                      <h4 class="course-cat-title">
                        <a href="#">DIgital marketing</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Feature Section END -->

<!-- Section cta start --> */}
      <section class="cta-intro section-padding-btm">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-8 col-md-12">
              <div class="cta-inner-wrapper">
                <div class="row justify-content-between align-items-center">
                  <div class="col-xl-8 col-lg-8 col-md-6">
                    <div class="section-heading mb-3 mb-lg-0 text-center text-md-start">
                      <span class="text-color">Let Us Help</span>
                      <h4>Finding Your Right Courses</h4>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="text-center text-md-end">
                      <a href="#" class="btn btn-main rounded">
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section cta End -->
    <!--course section start--> */}
      <section class="section-padding-btm course-filter-section">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-xl-6 col-lg-5">
              <div class="section-heading mb-50 text-center text-lg-start">
                <span class="subtitle">Course Trending</span>
                <h2 class="font-lg">Explore popular courses</h2>
              </div>
            </div>

            <div class="col-xl-6 col-lg-7">
              <ul class="course-filter text-center text-lg-end">
                <li class="active">
                  <a href="#" data-filter="*">
                    {" "}
                    All
                  </a>
                </li>
                <li>
                  <a href="#" data-filter=".cat1">
                    printing
                  </a>
                </li>
                <li>
                  <a href="#" data-filter=".cat2">
                    Web
                  </a>
                </li>
                <li>
                  <a href="#" data-filter=".cat3">
                    illustration
                  </a>
                </li>
                <li>
                  <a href="#" data-filter=".cat4">
                    media
                  </a>
                </li>
                <li>
                  <a href="#" data-filter=".cat5">
                    crafts
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="row course-gallery ">
            <div class="course-item cat1 cat5 col-lg-6 col-md-6">
              <div class="single-course style-2 bg-shade border-0">
                <div class="row g-0 align-items-center">
                  <div class="col-xl-5">
                    <div
                      class="course-thumb"
                      style={{
                        background: `url(${courseImg01})`,
                      }}
                    >
                      <span class="category">Design</span>
                    </div>
                  </div>
                  <div class="col-xl-7">
                    <div class="course-content">
                      <div class="course-price">$300</div>
                      <h3 class="course-title">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>
                      <div class="course-meta d-flex align-items-center">
                        <div class="author">
                          <img src={course2} alt="" class="img-fluid" />
                          <a href="#">Josephin</a>
                        </div>
                        <span class="students">
                          <i class="far fa-user-alt"></i>51 Students
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat2 cat4 col-lg-6 col-md-6">
              <div class="single-course style-2 bg-shade border-0">
                <div class="row g-0 align-items-center">
                  <div class="col-xl-5">
                    <div
                      class="course-thumb"
                      style={{
                        background: `url(${courseImg02})`,
                      }}
                    >
                      <span class="category">Design</span>
                    </div>
                  </div>
                  <div class="col-xl-7">
                    <div class="course-content">
                      <div class="course-price">$300</div>
                      <h3 class="course-title">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>
                      <div class="course-meta d-flex align-items-center">
                        <div class="author">
                          <img src={course2} alt="" class="img-fluid" />
                          <a href="#">Josephin</a>
                        </div>
                        <span class="students">
                          <i class="far fa-user-alt"></i>51 Students
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat3 cat2 col-lg-6 col-md-6">
              <div class="single-course style-2 bg-shade border-0">
                <div class="row g-0 align-items-center">
                  <div class="col-xl-5">
                    <div
                      class="course-thumb"
                      style={{
                        background: `url(${courseImg03})`,
                      }}
                    >
                      <span class="category">Design</span>
                    </div>
                  </div>
                  <div class="col-xl-7">
                    <div class="course-content">
                      <div class="course-price">$300</div>
                      <h3 class="course-title">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>
                      <div class="course-meta d-flex align-items-center">
                        <div class="author">
                          <img src={course2} alt="" class="img-fluid" />
                          <a href="#">Josephin</a>
                        </div>
                        <span class="students">
                          <i class="far fa-user-alt"></i>51 Students
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="course-item cat5 cat1 col-lg-6 col-md-6">
              <div class="single-course style-2 bg-shade border-0">
                <div class="row g-0 align-items-center">
                  <div class="col-xl-5">
                    <div
                      class="course-thumb"
                      style={{
                        background: `url(${courseImg06})`,
                      }}
                    >
                      <span class="category">Design</span>
                    </div>
                  </div>
                  <div class="col-xl-7">
                    <div class="course-content">
                      <div class="course-price">$300</div>
                      <h3 class="course-title">
                        {" "}
                        <a href="#">
                          Data Competitive Strategy law & Organization{" "}
                        </a>{" "}
                      </h3>
                      <div class="course-meta d-flex align-items-center">
                        <div class="author">
                          <img src={course2} alt="" class="img-fluid" />
                          <a href="#">Josephin</a>
                        </div>
                        <span class="students">
                          <i class="far fa-user-alt"></i>51 Students
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--course--> */}
      </section>
      {/* <!--course section end-->
<!--  Course style 1 --> */}

      <section class="section-padding bg-grey pb-70">
        <div class="container-fluid container-grid">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <div class="heading mb-100 text-center">
                <span class="subheading">Course Trending</span>
                <h2>Trending course over now</h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl col-lg-3 col-md-6">
              <div class="course-grid course-style-4 bg-white mb-80 mb-xl-0">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg01} alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="course-content">
                  <span class="course-price">$450</span>

                  <div class="d-flex align-items-center">
                    <div class="author me-3">
                      <img src={course2} alt="" class="img-fluid" />
                      <a href="#">Josephin</a>
                    </div>
                  </div>

                  <h3 class="course-title">
                    {" "}
                    <a href="#">
                      Adobe Lightroom: Photo Editing Masterclass
                    </a>{" "}
                  </h3>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>6 hours
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- COURSE END --> */}

            <div class="col-xl col-lg-3 col-md-6">
              <div class="course-grid course-style-4 bg-white mb-80 mb-xl-0">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg02} alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="course-content">
                  <span class="course-price">$220</span>
                  <div class="d-flex align-items-center">
                    <div class="author me-3">
                      <img src={course2} alt="" class="img-fluid" />
                      <a href="#">Josephin</a>
                    </div>
                  </div>

                  <h3 class="course-title">
                    {" "}
                    <a href="#">Writing With Flair: Become a Writer</a>{" "}
                  </h3>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>6 hours
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- COURSE END --> */}

            <div class="col-xl col-lg-3 col-md-6">
              <div class="course-grid course-style-4 bg-white mb-80 mb-xl-0">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg03} alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="course-content">
                  <span class="course-price">$300</span>
                  <div class="d-flex align-items-center">
                    <div class="author me-3">
                      <img src={course2} alt="" class="img-fluid" />
                      <a href="#">Josephin</a>
                    </div>
                  </div>

                  <h3 class="course-title">
                    {" "}
                    <a href="#">
                      Cinematography Course: Shoot Better Video{" "}
                    </a>{" "}
                  </h3>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>6 hours
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- COURSE END --> */}

            <div class="col-xl col-lg-3 col-md-6">
              <div class="course-grid course-style-4 bg-white mb-80 mb-xl-0">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg06} alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="course-content">
                  <span class="course-price">$300</span>
                  <div class="d-flex align-items-center">
                    <div class="author me-3">
                      <img src={course2} alt="" class="img-fluid" />
                      <a href="#">Josephin</a>
                    </div>
                  </div>

                  <h3 class="course-title">
                    {" "}
                    <a href="#">Mastering Data Competitive Strategy </a>{" "}
                  </h3>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>6 hours
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl col-lg-3 col-md-6">
              <div class="course-grid course-style-4 bg-white mb-80 mb-xl-0">
                <div class="course-header">
                  <div class="course-thumb">
                    <img src={courseImg06} alt="" class="img-fluid" />
                  </div>
                </div>

                <div class="course-content">
                  <span class="course-price">$300</span>
                  <div class="d-flex align-items-center">
                    <div class="author me-3">
                      <img src={course2} alt="" class="img-fluid" />
                      <a href="#">Josephin</a>
                    </div>
                  </div>

                  <h3 class="course-title">
                    {" "}
                    <a href="#">
                      Cinematography Course: Shoot Better Video{" "}
                    </a>{" "}
                  </h3>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>6 hours
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>26 Lectures
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- COURSE END --> */}
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="text-center">
                Want to help people learn, grow and achieve more in life?
                <a
                  href="#"
                  class="text-style2 text-decoration-underline fw-bold"
                >
                  All Courses
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--  Course style 1 End -->



<!-- Work Process Section Start --> */}
      <section class="work-process section-padding">
        <div class="container">
          <div class="row mb-70 justify-content-between">
            <div class="col-xl-5 col-lg-6">
              <div class="section-heading mb-4 mb-xl-0">
                <span class="subheading">How to Start</span>
                <h2 class="font-lg">4 steps start your journey with us</h2>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <p>
                Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                venenatis vestibulum. Etiam porta sem malesuada magna mollis
                euismod. Nullam id dolor id nibh ultricies vehicula ut id elit.
                Nullam quis risus eget urna mollis.
              </p>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-xl-7 pe-xl-5 col-lg-12">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="step-item ">
                    <div class="step-number bg-1">01</div>
                    <div class="step-text">
                      <h5>Signup with all info</h5>
                      <p>
                        Lorem ipsum dolor seat ameat dui too consecteture elite
                        adipaising.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="step-item">
                    <div class="step-number bg-2">02</div>
                    <div class="step-text">
                      <h5>Take your Admission</h5>
                      <p>
                        Lorem ipsum dolor seat ameat dui too consecteture elite
                        adipaising.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="step-item ">
                    <div class="step-number bg-3">03</div>
                    <div class="step-text">
                      <h5>Learn from online </h5>
                      <p>
                        Lorem ipsum dolor seat ameat dui too consecteture elite
                        adipaising.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="step-item ">
                    <div class="step-number bg-1">04</div>
                    <div class="step-text">
                      <h5>Get certificate</h5>
                      <p>
                        Lorem ipsum dolor seat ameat dui too consecteture elite
                        adipaising.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-12">
              <div class="video-section mt-5 mt-xl-0">
                <div class="video-content">
                  <img src={office01} alt="" class="img-fluid" />
                  <a href="#" class="video-icon video-popup">
                    <i class="fa fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Work Process Section End -->
<!-- Testimonial section start --> */}
      <section class="testimonial-2 section-padding-top pb-70 ">
        <div class="container">
          <div class="row ">
            <div class="col-xl-4">
              <div class="section-heading mb-5 mb-xl-0">
                <span class="subheading">Students Feedback</span>
                <h2 class="font-lg mb-10">Our Students Says</h2>
                <p class="mb-20">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusantium officia cupiditate .
                </p>

                <a href="#" class="btn btn-main rounded">
                  Join Now
                </a>
              </div>
            </div>
            <div class="col-lg-12 col-xl-8">
              <div class="testimonials-slides-2 owl-carousel owl-theme">
                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata01} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Cory Zamora</h4>
                        <span class="meta">Marketing Specialist</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text  mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata02} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Jackie Sanders</h4>
                        <span class="meta">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text  mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata03} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Nikolas Brooten</h4>
                        <span class="meta">Sales Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata04} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Terry Ambady</h4>
                        <span class="meta">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testimonial-item">
                  <div class="testimonial-inner">
                    <div class="quote-icon">
                      <i class="flaticon-left-quote"></i>
                    </div>

                    <div class="testimonial-text mb-30">
                      Cras vel purus fringilla, lobortis libero ut Proin a velit
                      convallis, fermentum orci in, rutrum diam. Duis elementum
                      odio a pharetra commodo.
                    </div>

                    <div class="client-info d-flex align-items-center">
                      <div class="client-img">
                        <img src={avata03} alt="" class="img-fluid" />
                      </div>
                      <div class="testimonial-author">
                        <h4>Nikolas Brooten</h4>
                        <span class="meta">Sales Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Testimonial section End -->
<!-- Counter Section start --> */}
      <section class="counter-section-2">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-5 pr-xl-5 col-lg-8">
              <div class="section-heading mb-5 mb-xl-0 text-center text-xl-start">
                <span class="subheading">
                  10,000+ Trusted Clients All Over The World
                </span>
                <h2 class="font-lg">
                  Some reasons why Start Your Online Learning with Us
                </h2>
              </div>
            </div>

            <div class="col-xl-7">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-1 mb-4 mb-lg-0">
                    <i class="flaticon-man"></i>
                    <div class="count">
                      <span class="counter h2">2</span>
                      <span>k</span>
                    </div>
                    <p>Students</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-2 mb-4 mb-lg-0">
                    <i class="flaticon-infographic"></i>
                    <div class="count">
                      <span class="counter h2">120</span>
                      <span>+</span>
                    </div>
                    <p>Online Courses</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="counter-box bg-3">
                    <i class="flaticon-satisfaction"></i>
                    <div class="count">
                      <span class="counter h2">100</span>
                      <span>%</span>
                    </div>
                    <p>Satisfaction</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- COunter Section End -->



<!-- Team section start --> */}
      <section class="team section-padding">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-6">
              <div class="section-heading text-center mb-70">
                <span class="subheading">Best Expert Team</span>
                <h2 class="font-lg">Our Professional Team</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5 mb-lg-0">
                <div class="team-img">
                  <img src={team4} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>SEO Expert</p>
                  </div>

                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5 mb-lg-0">
                <div class="team-img">
                  <img src={team1} alt="" class="img-fluid" />
                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>CEO, Developer</p>
                  </div>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5 mb-lg-0">
                <div class="team-img">
                  <img src={team2} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>Web eveloper</p>
                  </div>
                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-item mb-5 mb-lg-0">
                <div class="team-img">
                  <img src={team3} alt="" class="img-fluid" />

                  <ul class="team-socials list-inline">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="team-content">
                  <div class="team-info">
                    <h4>Harish Ham</h4>
                    <p>Marketer</p>
                  </div>

                  <div class="course-meta">
                    <span class="duration">
                      <i class="far fa-user-alt"></i>20 Students
                    </span>
                    <span class="lessons">
                      <i class="far fa-play-circle me-2"></i>2 Course
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Team section End -->
<!-- CTA Sidebar start --> */}
      <section class="cta">
        <div class="container">
          <div class="row cta-inner-section g-0">
            <div class="col-xl-6 col-lg-6">
              <div class="info-box style-1">
                <span class="subtitle"># Become a Instructor</span>
                <h2 class="font-lg mb-20 mt-10">
                  Become a Instructor at Tutori
                </h2>
                <a href="#" class="btn btn-main-2 rounded">
                  Apply Now <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>

            <div class="col-xl-6 col-lg-6">
              <div class="info-box">
                <span class="subtitle"># Want to join </span>
                <h2 class="font-lg mb-20 mt-10">Not Sure Where to Begin?</h2>
                <a href="#" class="btn btn-main rounded">
                  Get Started <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- CTA Sidebar end -->

<!-- Footer section start --> */}

      <Footer padding={true}></Footer>
      {/* <!-- Footer section End --> */}
    </div>
  );
};

export default Home2;
