import React from "react";

const Curriculam = () => {
  return (
    <div
      class="tab-pane fade"
      id="nav-topics"
      role="tabpanel"
      aria-labelledby="nav-topics-tab"
    >
      <div class="tutori-course-curriculum">
        <div class="curriculum-scrollable">
          <ul class="curriculum-sections">
            <li class="section">
              <div class="section-header">
                <div class="section-left">
                  <h5 class="section-title">Change simplification</h5>
                  <p class="section-desc">
                    Dacere agemusque constantius concessis elit videmusne quia
                    stoici constructio dissimillimas audiunt homerus
                    commendationes
                  </p>
                </div>
              </div>

              <ul class="section-content">
                <li class="course-item has-status course-item-lp_lesson">
                  <a class="section-item-link" href="#">
                    <span class="item-name">
                      The importance of data nowsaday
                    </span>
                    <div class="course-item-meta">
                      <span class="item-meta duration">10.30 min</span>
                      <i class="item-meta course-item-status"></i>
                    </div>
                  </a>
                </li>

                <li class="course-item has-status course-item-lp_lesson">
                  <a class="section-item-link" href="#">
                    <span class="item-name">
                      Why my organization should know about data
                    </span>
                    <div class="course-item-meta">
                      <span class="item-meta duration">20.30 min</span>
                      <i class="item-meta course-item-status"></i>
                    </div>
                  </a>
                </li>

                <li class="course-item course-item-lp_assignment course-item-lp_lesson">
                  <a class="section-item-link" href="#">
                    <span class="item-name">Assignments</span>
                    <div class="course-item-meta">
                      <span class="item-meta count-questions">
                        14 questions
                      </span>
                      <span class="item-meta duration">10.21 min</span>
                      <i class="fa item-meta course-item-status trans"></i>
                    </div>
                  </a>
                </li>
                <li class="course-item course-item-lp_quiz course-item-lp_lesson">
                  <a class="section-item-link" href="#">
                    <span class="item-name">Quiz 1</span>
                    <div class="course-item-meta">
                      <span class="item-meta count-questions">
                        14 questions
                      </span>
                      <span class="item-meta duration">5.67 min</span>
                      <i class="fa item-meta course-item-status trans"></i>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
            {/* <!-- section end --> */}
            <li class="section">
              <div class="section-header">
                <div class="section-left">
                  <h5 class="section-title">Key concepts </h5>
                  <p class="section-desc">
                    Dacere agemusque constantius concessis elit videmusne quia
                    stoici constructio dissimillimas audiunt homerus
                    commendationes
                  </p>
                </div>
              </div>

              <ul class="section-content">
                <li class="course-item has-status course-item-lp_lesson">
                  <a class="section-item-link" href="#">
                    <span class="item-name">
                      Basic understanding of data management concepts
                    </span>
                    <div class="course-item-meta">
                      <span class="item-meta duration">10 min</span>
                      <i class="item-meta course-item-status"></i>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
            {/* <!-- section end -->     */}
            <li class="section">
              <ul class="section-content">
                <li class="course-item has-status course-item-lp_lesson">
                  <a class="section-item-link" href="#">
                    <span class="item-name">Apply the principles </span>
                    <div class="course-item-meta">
                      <span class="item-meta duration">10 min</span>
                      <i class="item-meta course-item-status"></i>
                    </div>
                  </a>
                </li>

                <li class="course-item has-status course-item-lp_lesson">
                  <a class="section-item-link" href="#">
                    <span class="item-name">Lesson 2</span>
                    <div class="course-item-meta">
                      <span class="item-meta duration">20 min</span>
                      <i class="item-meta course-item-status"></i>
                    </div>
                  </a>
                </li>

                <li class="course-item has-status course-item-lp_lesson">
                  <a class="section-item-link" href="#">
                    <span class="item-name">Lesson 3</span>
                    <div class="course-item-meta">
                      <span class="item-meta duration">5 min</span>
                      <i class="item-meta course-item-status"></i>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
            {/* <!-- section end --> */}
          </ul>
          {/* <!-- Main ul end --> */}
        </div>
      </div>
    </div>
  );
};

export default Curriculam;
